import React, { useEffect, useState } from "react";
import AboutBanner from "../../components/banners/AboutBanner";
import { Row, Col, Container } from "reactstrap";
import { connect, useSelector } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import banner from "../../assets/temp/postcards-bg.jpg";
import PostCardsBanner from "../../components/banners/PostCardsBanner";

const ContactHosCards = (props) => {
  document.title = "Contact Us - HosCards";

  return (
    <div>
      <PostCardsBanner
        linkPage={[
          { type: "link", name: "Home", link: "/hos-postcards" },
          { name: "Contact Us" },
        ]}
        backgroundImage={banner}
        // destinationName="Share your moments"
        // destinationDescription=" "
        // destinationHoverDescription="Share your precious moments with friends and family as postcards through Emirates Post. Once created, you can download/print the postcards and drop it at your nearest Emirates Post box."
      />
      <section className="uploadSection">
        <div className="UploadCard" style={{ background: "#f9f9fa" }}>
          <div>
            <Container fluid>
              <h2 className="pb-3">Contact Us</h2>
              <p>
                Discover Shurooq is your one stop site to discover the latest
                and greatest events, services and special offers from Shurooq’s
                full range of destinations. Learn about what’s happening in the
                city, book tickets to events and enter competitions all from
                here.
              </p>
            </Container>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { raffleDataByInvoice } }) => ({});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactHosCards));
