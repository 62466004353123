import {useEffect} from "react"
import Routes from "./routes";
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';



function App() {
  const location = useLocation();
	useEffect(() => {
    ReactPixel.init('556522438278059');  
    ReactPixel.track('ViewContent');
       
   let cart = localStorage.getItem('products');

		if(!cart) localStorage.setItem('products', '[]')
	}, [])

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {'send_to': 'AW-16843097853/xTr2CPrerqUaEP2Ntd8-'});
     
  }	
  },[location] );


  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
