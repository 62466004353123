import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import rightchevron from "../../assets/images/icons/rightchevron.svg";
import leftchevron from "../../assets/images/icons/leftchevron.svg";
import DesignersBanner from "../../assets/images/designersbanner.png";
import earings from "../../assets/images/earings.png";
import axios from "axios";
import moment from "moment";
import Parser from "html-react-parser";
import { useParams } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import FavoriteIcon from "@material-ui/icons/Favorite";
import "./style1.scss";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { setCartData } from "../../actions/cart-action-type";
import TrustedLogo from "../../../src/assets/images/logos/trusted.svg";
import banner from "../../../src/assets/images/Gshar-Products34.jpg";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import ProductList from "../../components/productList";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";
import CollectionBanner from "../../components/banners/CollectionBanner";

const owlOptions = {
  nav: true,
  navText: [
    '<img src="https://img.icons8.com/ios/48/ffffff/circled-chevron-left.png"/>',
    '<img src="https://img.icons8.com/ios/48/ffffff/circled-chevron-right.png"/>',
  ],
  loop: false,
  dots: false,
  autoplay: false,
  loop: false,
  items: 1,
};
const options = {
  nav: true,
  loop: false,
  autoplay: false,
  navText: [``, ``],
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 1,
    },
    767: {
      items: 2,
    },
    991: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    // 1600: {
    //   items: 5,
    // },
  },
};

const ProductDetail = (props) => {
  const carouselRef = useRef();
  const [product, setProduct] = useState([]);
  const [variationAttrs, setVariationAttrs] = useState([]);
  const [activeVariant, setActiveVariant] = useState([]);
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [selectedVariantAttr, setSelectedVariantAttr] = useState([]);
  const [showption, setShowoption] = useState(null);
  const [showproducts, setShowproducts] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [alertText, setAlertText] = useState("");
  const [alert, setAlert] = useState(false);
  const [qty, setQty] = useState(0);
  const [cartMax, setCartMax] = useState(0);
  const [favorite, setFavorite] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [offset, setOffset] = useState(null);
  const [pageoffset, setPageoffset] = useState(null);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [productFlag, setProductFlag] = useState(false);
  const [notifyState, setNotifyState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const productCarousel = useRef(null);
  const { vertical, horizontal, open } = notifyState;

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const storeData = useSelector((store) => store);
  localStorage.setItem("pathname", history.location.pathname);
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("jwt") || ""}`,
  };

  useEffect(() => {
    handleResetData();
    globalConfig.isStorePage = true;
    window.scrollTo({ behavior: "smooth", top: 0 });
    const bodyFormData = new FormData();
    bodyFormData.append("action", "getProduct");
    bodyFormData.append("slug", params.product_slug);
    axios
      .post(process.env.REACT_APP_BASE_URL, bodyFormData, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.data) {
          const tempProduct = res.data.data;
          console.log(tempProduct);
          setFavorite(tempProduct.favorite);
          setProduct(tempProduct);
          setProductImages(tempProduct.images);
          setShowoption(tempProduct);
          setVariationAttrs(tempProduct.variation_attributes);
          setVariants(tempProduct.variants ? tempProduct.variants : []);

          if (tempProduct.variation_attributes) {
            let variantArr = Object.keys(tempProduct.variation_attributes).map(
              (k) => tempProduct.variation_attributes[k]
            );
            setActiveVariant(variantArr.find((attr) => attr.enable === 0));
          }
          // if (!variantArr.length) {
          const cart = JSON.parse(localStorage.getItem("products"));

          const selectedVariantAtt = tempProduct.variation_attributes.find(
            (vari) => cart.some((item) => item.variant_id === vari.id)
          );

          if (selectedVariantAtt) {
            setSelectedVariant((prevState) => {
              let futureState = {
                ...prevState,
                [tempProduct.variants[0].name]:
                  selectedVariantAtt.variant_options[0],
              };
              console.log(prevState, futureState);

              let data = checkInitialAvaliable(
                futureState,
                tempProduct.variation_attributes,
                tempProduct
              );
              console.log(data);
              setSelectedVariantAttr(data);
              const images = data.map((d) => d.img);
              setProduct({
                ...tempProduct,
                images: [...images, ...tempProduct.images],
              });
              // if (!Number(data.qty)) {
              //   promptErrorMessage(false);
              // }
              if (data.length > 0) return futureState;
              else return prevState;
            });
          } else {
            setSelectedVariant([]);
            setSelectedVariantAttr([]);
          }
          let flag = cart.find(
            (item) => item.product_id === tempProduct.product_id
          );
          setQty(flag ? flag.qty : 0);
          // }

          setProductFlag(true);
          setLoading(false);

          // const variants = JSON.parse(product.variants)
          // let defaultSelect;

          // for (var i = 0; i < variants.length; i++) {
          //   defaultSelect = {
          //     ...defaultSelect,
          //     [variants[i].name]: variants[i].options[0].option_name
          //   }
          // }

          // setSelectedVariant(defaultSelect)
        }
      });
  }, [history.location.pathname]);

  const checkInitialAvaliable = (checkState, variationAttrs, product) => {
    let data;
    if (variationAttrs) {
      let variantArr = Object.keys(variationAttrs).map(
        (k) => variationAttrs[k]
      );
      let keys = Object.keys(checkState);
      if (product.variation_attributes) {
        data = variantArr.filter((item) => {
          let flag = 0;
          for (var i = 0; i < keys.length; i++) {
            if (
              item.variant_options.filter(
                (item2) => item2 === checkState[keys[i]]
              ).length > 0
            ) {
              flag++;
            }
          }
          return flag === keys.length && item.enable === 1;
        });
      }
    }
    return data || [];
  };

  useEffect(() => {
    const bodyForm = new FormData();
    bodyForm.append("action", "storeData");
    axios.post(process.env.REACT_APP_BASE_URL, bodyForm).then((res) => {
      const relatedTemp = res.data.data.products.filter(
        (prod, i) => prod.product_id != product.product_id
      );
      var sortedProducts = relatedTemp.sort((a, b) =>
        moment(b.created_at.split("")[0]).diff(
          moment(a.created_at.split("")[0])
        )
      );
      setRelatedProducts(sortedProducts.splice(0, 20));
    });
  }, [product]);

  const handleResetData = () => {
    setVariationAttrs([]);
    setActiveVariant([]);
    setVariants([]);
    setShowoption(null);
    setShowproducts([]);
    setProductImages([]);
    setRelatedProducts([]);
    setAlertText("");
    setAlert(false);
    setQty(0);
    setCartMax(0);
    setFavorite(false);
    setProductFlag(false);
  };

  const onVariantEvent = (index, value) => {};

  const checkEvent = (name, value) => {
    return selectedVariant[name] === value;
  };

  const checkEnable = (name, value) => {
    let futureState = {
      ...selectedVariant,
      [name]: value,
    };

    let data = checkAvaliable(futureState);

    return data.length === 0;
  };

  const changeVariant = (e) => {
    setSelectedVariant((prevState) => {
      let futureState = {
        ...prevState,
        [e.target.name]: e.target.value,
      };

      let data = checkAvaliable(futureState);
      setSelectedVariantAttr(data);
      const images = data.map((d) => d.img);
      setProduct({ ...product, images: [...images, ...productImages] });
      // if (!Number(data.qty)) {
      //   promptErrorMessage(false);
      // }
      if (data.length > 0) return futureState;
      else return prevState;
    });
  };

  useEffect(() => {
    if (carouselRef.current && selectedVariantAttr.length) {
      carouselRef.current.goTo(
        selectedVariantAttr.findIndex((selected) => selected.img)
      );
    }
  }, [selectedVariantAttr]);

  const checkAvaliable = (checkState) => {
    let data;
    if (variationAttrs) {
      let variantArr = Object.keys(variationAttrs).map(
        (k) => variationAttrs[k]
      );
      let keys = Object.keys(checkState);
      if (product.variation_attributes) {
        data = variantArr.filter((item) => {
          let flag = 0;
          for (var i = 0; i < keys.length; i++) {
            if (
              item.variant_options.filter(
                (item2) => item2 === checkState[keys[i]]
              ).length > 0
            ) {
              flag++;
            }
          }
          return flag === keys.length && item.enable === 1;
        });
      }
    }
    return data || [];
  };

  const addToCart = (noOfQty, incrDecr, pageRedirect) => {
    let keys = Object.keys(selectedVariant);
    if (keys.length !== variants.length) {
      promptWarnMessage();
      return;
    }

    let cart = JSON.parse(localStorage.getItem("products"));
    let tempnewdata = {
      product_id: product.product_id,
      qty: noOfQty,
      product_slug: product.slug,
      title: product.product_title,
    };
    let flag;
    if (showption) {
      let flag = cart.filter((item) => item.product_id === product.product_id);
      if (flag.length > 0) {
        if (incrDecr === "up") {
          if (noOfQty > showption.qty && showption.qty != null) {
            promptErrorMessage(true);
            return;
          }
        }
        flag[0].qty = noOfQty;
        if (flag[0].qty === 0) {
          cart = cart.filter((item) => item.product_id != showption.product_id);
        }
        setQty(noOfQty);
        promptSuccessMessage(noOfQty);
      } else {
        if (incrDecr === "up") {
          if (noOfQty > showption.qty && showption.qty != null) {
            if (!Number(showption.qty)) {
              promptErrorMessage(false);
            } else {
              promptErrorMessage(true);
            }
            return;
          }
        }
        cart = [
          ...cart,
          {
            ...tempnewdata,
            variant_id: showption.id,
            price: showption.price,
            variant_options: showption.variant_options,
            images: product.images,
            product_description: product.product_description,
          },
        ];
        setQty(noOfQty);
        promptSuccessMessage(noOfQty);
      }
      localStorage.setItem("products", JSON.stringify(cart));
      dispatch(setCartData());
      if (pageRedirect) {
        history.push(`/stores/gsharshop/cart`);
      }
    } else {
      if (variationAttrs.length == 0) {
        let flag = cart.filter(
          (item) => item.product_id === tempnewdata.product_id
        );

        if (flag.length > 0) {
          if (incrDecr === "up") {
            if (noOfQty > product.qty && product.qty != null) {
              promptErrorMessage(true);
              return;
            }
          }
          flag[0].qty = noOfQty;
          if (flag[0].qty === 0) {
            cart = cart.filter((item) => item.product_id != product.product_id);
          }
          setQty(noOfQty);
          promptSuccessMessage(noOfQty);
        } else {
          if (incrDecr === "up") {
            if (noOfQty > product.qty && product.qty != null) {
              if (!Number(product.qty)) {
                promptErrorMessage(false);
              } else {
                promptErrorMessage(true);
              }
              return;
            }
          }
          cart = [
            ...cart,
            {
              ...tempnewdata,
              price: product.price,
              images: product.images,
              product_description: product.product_description,
            },
          ];
          setQty(noOfQty);
          promptSuccessMessage(noOfQty);
        }
        localStorage.setItem("products", JSON.stringify(cart));
        dispatch(setCartData());
        if (pageRedirect) {
          history.push(`/stores/gsharshop/cart`);
        }
      } else {
        promptWarnMessage();
      }
    }
  };

  const promptSuccessMessage = (quantity) => {
    if (quantity === 0) {
      setAlertText(`Item removed from Cart`);
    } else if (quantity === 1) {
      setAlertText(`Added Item to Cart`);
    } else {
      setAlertText(`Updated Item Quantity to ${quantity}  `);
    }
    setSuccess(true);
    // setNotifyState({ ...notifyState, open: true });
  };

  const promptErrorMessage = (isLess) => {
    if (isLess) {
      setAlertText(`Sorry, Seller has Limited Quantity Available `);
    } else {
      setAlertText(`Product is Out of Stock`);
    }
    setSuccess(false);
    setNotifyState({ ...notifyState, open: true });
  };

  const promptWarnMessage = () => {
    setAlertText(`Please Select a Variant`);
    setSuccess(false);
    setNotifyState({ ...notifyState, open: true });
  };

  useEffect(() => {
    let data = checkAvaliable(selectedVariant);
    setShowoption(data[0]);
    if (data.length || data.id) {
      const cart = JSON.parse(localStorage.getItem("products"));
      let flag = cart.find((item) => item.variant_id === data[0].id || data.id);
      setQty(flag ? flag.qty : 0);
    }
  }, [selectedVariant]);

  useEffect(() => {
    let cartMax = showption
      ? Number(showption.qty)
      : Number(product.qty)
      ? Number(product.qty)
      : 0;
    cartMax = Number(cartMax) > 9 ? 9 : Number(cartMax);
    setCartMax(cartMax);
  }, [showption]);

  const handleFavorite = () => {
    const bodyForm = new FormData();
    bodyForm.append(
      "action",
      favorite ? "removeFavouriteProduct" : "addFavouriteProduct"
    );
    bodyForm.append("product_id", product.product_id);
    axios
      .post(process.env.REACT_APP_BASE_URL, bodyForm, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        setFavorite(res.data.productData.favorite);
        setProduct(res.data.productData);
      });
  };

  useEffect(() => {
    // const winOffset = window.pageYOffset
    // const ref = document.getElementById("btn-grp");
    // const sticky = ref.offsetTop;
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    // window.addEventListener("scroll", () =>  setOffset(sticky))
    // window.addEventListener("scroll", () =>    setPageoffset(window.pageYOffset))
  }, []);

  // console.log("page"+pageoffset)
  // console.log("wrapper"+offset)

  const handleClose = () => {
    setNotifyState({ ...notifyState, open: false });
  };

  const options1 = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const renderLoader = () => (
    <ContentLoader
      speed={2}
      viewBox="0 0 200 200"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="200" height="200" />
    </ContentLoader>
  );

  return (
    <div className="productdetail">
      <Helmet>
        <title>Gshar - {product?.product_title || ""}</title>
        <meta
          name="description"
          content={product?.product_description?.substring(0, 100) || ""}
        />
      </Helmet>
      <StoreMenu productDetail={true} />
      {/* <CollectionBanner
        backgroundImage={banner}
        titleArr={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "gsharshop",
            link: `/stores/gsharshop`,
          },
          {
            title: product.product_title,
            link: `/stores/${localStorage.getItem(
              "storeName"
            )}/product_detail/${product.slug}`,
          },
        ]}
        title={product.product_title}
      /> */}

      <div className="container py-5" style={{ marginTop: "4rem" }}>
        <div className="d-flex justify-content-between">
          {/* <h2 className="sec-title mb-5">{destination.name}</h2> */}

          <h6 className="mb-0">
            <a href="#!" className="text-body"></a>
          </h6>
        </div>

        <Row className="product-container">
          <Col md={6}>
            <Card>
              {!loading && product.images && product.images.length > 0 ? (
                <OwlCarousel options={owlOptions} ref={carouselRef}>
                  {product.images.map((img, index) => (
                    <div className="fav-parent">
                      <LazyLoadImage
                        className="img-fluid product-image"
                        src={img}
                        alt={product.product_title}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        effect="blur"
                      />
                      {localStorage.getItem("jwt") && (
                        <FavoriteIcon
                          onClick={handleFavorite}
                          className="fav-icon"
                          style={{
                            fontSize: 44,
                            color: favorite ? "red" : "",
                            display: "none",
                          }}
                        />
                      )}
                    </div>
                  ))}
                </OwlCarousel>
              ) : (
                renderLoader()
              )}
              {/* <OwlCarousel options={owlOptions} items={1}>
                    <div className="fav-parent">
                      <img
                        className="img-fluid product-image"
                        src={earings}
                        alt={product.product_title}
                        style={{ height: "600px" }}
                      />
                      {localStorage.getItem("loginStatus") === "success" && (
                        <FavoriteIcon
                          onClick={handleFavorite}
                          className="fav-icon"
                          style={{ fontSize: 44, color: favorite ? "red" : "" }}
                        />
                      )}
                    </div>
                </OwlCarousel> */}
            </Card>
          </Col>

          <Col className="product_details" md={6}>
            <Row className="mb-1">
              {/*  <Col>
                {product.collections?.map((collection, i) => (
                  <a
                    href={`/stores/${localStorage.getItem(
                      "storeName"
                    )}/collections/${collection.slug}`}
                    className="text-muted"
                  >
                    {collection.collection_name}{" "}
                    {product.collections.length !== i + 1 && ", "}
                  </a>
                ))}
              </Col>

                    */}

              {!loading ? (
                <>
                  <h3
                    className="mb-2 mt-2 mt-md-0"
                    style={{
                      color: "#202434",
                      fontSize: "40px",
                      fontWeight: 600,
                    }}
                  >
                    {product.product_title}
                  </h3>
                  <div className="mt-2 mt-md-0">
                    {/* <span className="font-size-lg font-weight-bold text-gray-350 text-decoration-line-through">$115.00</span> */}
                    <span
                      style={{
                        color: "#202434 !important",
                        fontSize: "30px",
                        fontWeight: 600,
                      }}
                    >
                      AED {showption ? showption.price : product.price}
                    </span>
                    <span
                      style={{
                        marginLeft: "10px",
                        color: "#B0B0B0",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {selectedVariantAttr && selectedVariantAttr.length
                        ? Number(selectedVariantAttr[0].qty)
                          ? "(In Stock)"
                          : "(Out of Stock !!!)"
                        : productFlag &&
                          Object.keys(product).length &&
                          !product.variants?.length
                        ? !Number(product.qty) && product.qty != null
                          ? "(Out of Stock !!!)"
                          : "(In Stock)"
                        : ""}
                    </span>
                    {/* selectedVariantAttr is the variant of the product to check whether there is variant and its qty */}
                    {/* product flag is updated to true when product has value , its because of the product data type is different, 
                  then check whether is has variant , if no variant shows nothing , if product qty is 0 shows out of stock */}
                    {/* <span className="font-size-sm ms-2">
                  
                  </span> */}
                  </div>
                </>
              ) : (
                <>
                  <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="17" rx="4" ry="4" width="500" height="13" />
                    <rect x="0" y="40" rx="3" ry="3" width="500" height="13" />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="40" rx="3" ry="3" width="200" height="13" />
                  </ContentLoader>
                </>
              )}

              <Col md="auto" style={{ marginTop: "25px" }}>
                <div className="product-detail">
                  <h2
                    style={{
                      color: "#2E323B",
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  >
                    Product details
                  </h2>
                  {!loading ? (
                    <>
                      <p
                        className="mt-2"
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        {productFlag &&
                          product &&
                          Parser(product.product_description, options1)}
                      </p>
                      {variants.map((item, i) => (
                        <div key={i} className="form-group">
                          <p className="mb-3">{item.name}</p>

                          <div className="mb-2">
                            {item.options
                              ? item.options.map((option, j) => (
                                  <div
                                    key={j}
                                    className="custom-control custom-control-inline custom-control-size mb-5"
                                  >
                                    <input
                                      key={Math.random()}
                                      type="radio"
                                      className="custom-control-input"
                                      name={item.name}
                                      defaultChecked={checkEvent(
                                        item.name,
                                        option.option_name
                                      )}
                                      disabled={checkEnable(
                                        item.name,
                                        option.option_name
                                      )}
                                      onChange={changeVariant}
                                      id={`variants-${
                                        option.option_name + option.option_id
                                      }`}
                                      value={option.option_name}
                                    />
                                    <label
                                      className="custom-control-label"
                                      onClick={() =>
                                        onVariantEvent(i, option.option_name)
                                      }
                                      htmlFor={`variants-${
                                        option.option_name + option.option_id
                                      }`}
                                    >
                                      {option.option_name}
                                    </label>
                                  </div>
                                ))
                              : ""}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <ContentLoader viewBox="0 0 380 70">
                        <rect
                          x="0"
                          y="17"
                          rx="4"
                          ry="4"
                          width="500"
                          height="13"
                        />
                        <rect
                          x="0"
                          y="40"
                          rx="3"
                          ry="3"
                          width="500"
                          height="13"
                        />
                      </ContentLoader>
                      <ContentLoader viewBox="0 0 380 70">
                        <rect
                          x="0"
                          y="40"
                          rx="3"
                          ry="3"
                          width="200"
                          height="13"
                        />
                      </ContentLoader>
                    </>
                  )}
                </div>

                {!loading ? (
                  <>
                    {productFlag && product && product.tags && (
                      <div className="product-detail">
                        <span className="h2">Tags : </span>
                        {JSON.parse(product.tags).map((tag, i) => (
                          <a
                            href={`/stores/${localStorage.getItem(
                              "storeName"
                            )}/tag/${tag.value}`}
                            className="product-tag"
                          >
                            {tag.value}
                            {JSON.parse(product.tags).length !== i + 1 && ", "}
                          </a>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <ContentLoader viewBox="0 0 380 70">
                    <rect x="0" y="17" rx="4" ry="4" width="500" height="13" />
                    <rect x="0" y="40" rx="3" ry="3" width="500" height="13" />
                  </ContentLoader>
                )}
              </Col>

              {/* <p
                style={{
                  marginTop: "30px",
                  marginBottom: "40px",
                }}
              >
                <span
                  className="mr-4"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#202434",
                  }}
                >
                  Share:
                </span>
                <a
                  style={{
                    marginLeft: "20px",
                  }}
                  className="custom-btn custom-btn-xxs custom-btn-circle custom-btn-light font-size-xxxs text-gray-350"
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    style={{
                      color: "#F89828",
                      fontSize: "16px",
                    }}
                    className="fab fa-twitter"
                  ></i>
                </a>
                <a
                  style={{
                    marginLeft: "20px",
                  }}
                  className="custom-btn custom-btn-xxs custom-btn-circle custom-btn-light font-size-xxxs text-gray-350"
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    style={{
                      color: "#F89828",
                      fontSize: "16px",
                    }}
                    className="fab fa-facebook-f"
                  ></i>
                </a>
                <a
                  style={{
                    marginLeft: "20px",
                  }}
                  className="custom-btn custom-btn-xxs custom-btn-circle custom-btn-light font-size-xxxs text-gray-350"
                  href="https://pinterest.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    style={{
                      color: "#F89828",
                      fontSize: "16px",
                    }}
                    className="fab fa-pinterest-p"
                  ></i>
                </a>
              </p> */}

              <img
                className="opacity-50"
                style={{
                  marginTop: "40px",
                  height: "30px",
                  width: "fit-content",
                  marginBottom: "75px",
                }}
                src={TrustedLogo}
              />

              {/* <div className="d-flex justify-content-between flex-column flex-md-row">
                {qty > 0 ? (
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#EFF5FC",
                      padding: "10px",
                      height: "fit-content",
                      width: "fit-content",
                      borderRadius: "5px",
                      color: "#202434",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    <div
                      style={{
                        padding: "6px 14px",
                        background: "#F89828",
                        borderRadius: "5px",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: 700,
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => addToCart(qty - 1, "down", false)}
                    >
                      -
                    </div>

                    {qty}

                    <div
                      style={{
                        padding: "6px 14px",
                        background: "#F89828",
                        borderRadius: "5px",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: 700,
                        marginLeft: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => addToCart(qty + 1, "up", false)}
                    >
                      +
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div> */}

              <div>
                {/* <div className="form-group">
                  <p className="mb-3">
                    Color: <strong id="colorCaption">Black</strong>
                  </p>

                  <div className="mb-5">
                    {productImgs.map ((img, index) => (
                      <div key={index} className="custom-control custom-control-inline custom-control-img">
                        <input type="radio" className="custom-control-input" id={`imgRadioOne${index}`} defaultChecked={index === 0}  name="imgRadio" data-toggle="form-caption" value="white" />
                        <label className="custom-control-label" htmlFor={`imgRadioOne${index}`}>
                          <span className="embed-responsive embed-responsive-1by1 bg-cover" style={{ background: `url("https://bo.discovershurooq.ae/files/${img}")` }}></span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div> */}

                <div className="form-group">
                  <Row>
                    {/* <Col lg="auto"> */}
                    {/* <select
                        className="custom-select mb-2"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                      >
                        {[...new Array(cartMax).keys()].map((item) => (
                          <option key={item} value={item + 1}>
                            {item + 1}
                          </option>
                        ))}
                      </select> */}
                    {/*<input
                        className="custom-select mb-2"
                        type="number"
                        value={qty}
                        onChange={e => setQty(e.target.value)}
                        min={1}
                        max={cartMax}
                      />*/}
                    {/* </Col> */}
                    <Col
                      md="auto"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className={`${
                          width < 767 && "fixbottom"
                        } d-flex align-items-center justify-content-between`}
                        id="btn-grp"
                        style={{
                          width: "100%",
                        }}
                      >
                        {qty > 0 ? (
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#EFF5FC",
                              padding: "10px",
                              height: "fit-content",
                              width: "fit-content",
                              borderRadius: "5px",
                              color: "#202434",
                              fontSize: "16px",
                              fontWeight: 700,
                            }}
                          >
                            <div
                              style={{
                                padding: "6px 14px",
                                background: "#F89828",
                                borderRadius: "5px",
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: 700,
                                marginRight: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => addToCart(qty - 1, "down", false)}
                            >
                              -
                            </div>

                            {qty}

                            <div
                              style={{
                                padding: "6px 14px",
                                background: "#F89828",
                                borderRadius: "5px",
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: 700,
                                marginLeft: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => addToCart(qty + 1, "up", false)}
                            >
                              +
                            </div>
                          </div>
                        ) : (
                          // <div className="counter-btn">
                          //   <button
                          //     className="btn btn-link px-4"
                          //     onClick={() => addToCart(qty - 1, "down", false)}
                          //   >
                          //     <i className="fas fa-minus"></i>
                          //   </button>
                          //   <p>{qty}</p>
                          //   <button
                          //     className="btn btn-link px-4"
                          //     onClick={() => addToCart(qty + 1, "up", false)}
                          //   >
                          //     <i className="fas fa-plus"></i>
                          //   </button>
                          // </div>
                          <button
                            // disabled={
                            //   selectedVariantAttr.length
                            //     ? !Number(selectedVariantAttr[0].qty)
                            //     : !Number(product.qty)
                            // }
                            onClick={() => addToCart(qty + 1, "up", false)}
                            className="actionbutton"
                            style={{ width: width < 767 ? "170px" : "200px" }}
                            // style={{
                            //   background: "#F89828",
                            //   border: "none",
                            //   fontSize: "8px",
                            //   fontWeight: 700,
                            //   color: "#fff",
                            //   borderRadius: "12px",
                            //   boxShadow: "0px 7px 10px rgba(0, 0, 0, 0.05)",
                            // }}
                          >
                            Add to Cart
                            <i className="fas ms-2 fa-shopping-cart"></i>
                          </button>
                        )}
                        {qty > 0 ? (
                          <button
                            className="actionbutton"
                            style={{ width: width < 767 ? "170px" : "200px" }}
                            onClick={() =>
                              history.push(
                                `/stores/${localStorage.getItem(
                                  "storeName"
                                )}/cart`
                              )
                            }
                          >
                            Go to Cart
                          </button>
                        ) : (
                          <button
                            style={{ width: width < 767 ? "170px" : "200px" }}
                            className="actionbutton"
                            onClick={() => addToCart(qty + 1, "up", true)}
                          >
                            Buy Now
                          </button>
                        )}
                      </div>
                      {(selectedVariantAttr.length > 0
                        ? selectedVariantAttr[0].qty > 0 &&
                          selectedVariantAttr[0].qty <= 10
                        : Number(product.qty) > 0 &&
                          Number(product.qty) <= 10) &&
                        qty > 0 && (
                          <p>
                            <br></br>
                            Hurry up! Only{" "}
                            {selectedVariantAttr.length
                              ? selectedVariantAttr[0].qty
                              : Number(product.qty)}{" "}
                            Left
                          </p>
                        )}
                    </Col>
                    <Snackbar
                      open={open}
                      autoHideDuration={5000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical, horizontal }}
                      style={{ marginBottom: "5rem" }}
                    >
                      <Alert
                        severity={success ? "success" : "error"}
                        onClose={handleClose}
                        style={{ backgroundColor: success ? "black" : "red" }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            marginRight: "1rem",
                          }}
                        >
                          {alertText}{" "}
                        </span>
                        {success && (
                          <u>
                            <span
                              className="alert-link"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(
                                  `/stores/${localStorage.getItem(
                                    "storeName"
                                  )}/cart`
                                );
                              }}
                            >
                              {"Go to Cart"}
                            </span>
                          </u>
                        )}
                      </Alert>
                    </Snackbar>
                  </Row>

                  {/* <!-- Share --> */}
                </div>
              </div>
            </Row>
          </Col>
        </Row>

        <div
          style={{
            width: "100%",
            marginTop: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-end"
              style={{ marginBottom: "30px" }}
            >
              <h2 className="product-sec-title">
                Related <span style={{ color: "#F89828" }}>Products</span>
              </h2>

              <h6 className="cursor-pointer">
                <a href={`/stores/gsharshop/products`} className="text-body">
                  See All{" "}
                  <i
                    className="fas fa-long-arrow-alt-right"
                    style={{ color: "#F89828", marginLeft: ".5rem" }}
                  ></i>
                </a>
              </h6>
              {/* <Link to="/stores" className="text-body">
            <i className="fas fa-long-arrow-alt-left me-2"></i>
            Back
          </Link> */}
            </div>
            {!loading && !!relatedProducts.length ? (
              <div id="product-slider" className="products">
                <OwlCarousel
                  ref={productCarousel}
                  options={{ ...options, dots: false }}
                >
                  {relatedProducts.map(
                    (product, index) =>
                      Number(product.qty) > 0 && (
                        <div
                          key={product.product_id}
                          className={`product-item ${
                            index == 0 ? "product-item-first" : ""
                          }`}
                        >
                          <ProductList product={product}></ProductList>
                        </div>
                      )
                  )}
                </OwlCarousel>
                <div
                  className="d-flex justify-content-end"
                  style={{ width: "100%", marginBottom: "35px" }}
                >
                  <div
                    style={{
                      padding: "9px 15px",
                      cursor: "pointer",
                      border: "2px solid #F89828",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      productCarousel.current.prev();
                    }}
                  >
                    <img src={leftchevron} alt="" />
                  </div>
                  <div
                    style={{
                      padding: "9px 15px",
                      cursor: "pointer",
                      border: "2px solid #F89828",
                      background: "#F89828",
                      borderRadius: "8px",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      productCarousel.current.next();
                    }}
                  >
                    <img src={rightchevron} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              <Row className="m-0">
                {["", "", "", ""].map((data, index) => (
                  <Col className="mb-4" lg={3} md={6}>
                    <ContentLoader
                      speed={2}
                      viewBox="0 0 520 800"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect
                        x="1"
                        y="0"
                        rx="3"
                        ry="3"
                        width="700"
                        height="1000"
                      />
                    </ContentLoader>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </div>
      </div>
      <div style={{ background: "#E4EBF3", padding: "50px 0" }}>
        <div
          className="container guarantees"
          style={{
            background: "#E7881A",
            borderRadius: "15px",
            // margin: "20px",
          }}
        >
          <div
            className="row"
            style={{
              padding: "50px 75px",
            }}
          >
            <EcommerceGuarantees />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(withRouter(ProductDetail));
