import React, { useEffect, useState } from "react";
// import headBanner from "../../../assets/images/backgrounds/register.png";
import headBanner from "../../../assets/images/headerImages/signup.jpg";
import productbg from "../../../assets/images/productbg.jpg";
import arrowRight from "../../../assets/images/arrow-narrow-right.png";
import OwlCarousel from "react-owl-carousel2";
import Parser from "html-react-parser";
import styled from "styled-components";
import "./style.scss";
import { Link } from "react-router-dom";

export default function CollectionBanner(props) {
  const {
    backgroundImage = headBanner,
    titleArr,
    title,
    designerProduct,
  } = props;
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const header = document.getElementById("header");
    //  const headerHeight = header.offsetHeight
    window.addEventListener("resize", setHeight(header?.offsetHeight));
    console.log("header" + height);
  }, [height]);

  const options1 = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const Slider = styled.div`
    display: flex;
    // margin-top: 60px;
    .owl-stage {
      display: flex;
      height: calc(550px + ${height}px);
    }
    .owl-dots {
      position: absolute;
      left: 0;
      top: 90%;
    }
    .owl-prev {
      position: absolute;
      top: 40%;
      left: 0%;
      display: block !important;
    }
    .owl-next {
      position: absolute;
      top: 40%;
      right: 0%;
      display: block !important;
    }
    img {
      height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  `;
  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        background: `linear-gradient(0deg, rgba(0, 8, 14, 0.7), rgba(0, 8, 14, 0.7)), url(${encodeURI(
          backgroundImage
        )})`,
        backgroundSize: "cover",
        position: "relative",
        backgroundPositionY: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "100px",
          left: "10%",
          zIndex: "9",
          color: "#FFFFFF",
          lineHeight: "1.5",
        }}
      >
        <h1 className="bannerDesc">{title}</h1>
      </div>

      {designerProduct && (
        <div className="designer-container">
          <div className="designer-logo">
            <div
              className="category-grid"
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${designerProduct.featured_image})`,
                minWidth: "205px",
                height: "200px",
              }}
            ></div>
          </div>
          <div className="designer-name">{designerProduct.collection_name}</div>
          <div className="designer-desc">
            {Parser(designerProduct.description, options1)}
          </div>
        </div>
      )}

      <div
        className="bread-crumbs"
        style={{
          position: "absolute",
          bottom: "50px",
          right: "10%",
          zIndex: "9",
          color: "#FFFFFF",
          lineHeight: "1.5",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {titleArr.map((value, index) => (
          <>
            {index !== 0 && (
              <span
                style={{
                  width: "10px",
                  height: "3px",
                  backgroundColor: "#F89828",
                  borderRadius: "55px",
                }}
              />
            )}
            <Link to={value.link}>
              <p
                style={{
                  margin: "0 15px",
                  color:
                    index == titleArr.length - 1
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.7)",
                }}
              >
                {value.title}
              </p>
            </Link>
          </>
        ))}
      </div>
    </div>
  );
}
