import React, { useEffect, useState } from "react";
import "./style.scss";

export default function TagsFilter({ tempProducts, setProducts }) {
  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    var tagsArr = [];
    tempProducts.map((d) => {
      if (d.tags) {
        d.tags = JSON.parse(d.tags);
        tagsArr.push(...d.tags);
      }
    });
    var filteredTags = [];
    for (var i = 0; i <= tagsArr.length; i++) {
      if (
        tagsArr.filter((tag) => tagsArr[i]?.value === tag?.value).length >= 3
      ) {
        filteredTags.push(tagsArr[i]);
      }
    }
    setTagsList(
      filteredTags.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
      )
    );
  }, [tempProducts]);

  const handleFilterTags = (selectedTag) => {
    if (selectedTag === "All") {
      setProducts(tempProducts);
      setTagsList((prev) => {
        prev.map((list) => {
          list.active = false;
          return list;
        });
        return prev;
      });
    } else {
      setTagsList((prev) => {
        prev.map((list) => {
          if (list.value === selectedTag) {
            list.active = true;
          } else {
            list.active = false;
          }
          return list;
        });
        return prev;
      });
      const filteredProducts = tempProducts.filter(
        (temp) =>
          temp.tags && temp.tags.find((tag) => tag.value === selectedTag)
      );
      setProducts(filteredProducts);
    }
  };

  return (
    <div
      className="filter-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div
        className="tags text-capitalize"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          overflow: "auto",
          padding: "15px 10px",
        }}
      >
        <button
          style={{
            width: "max-content",
            padding: "10px 25px",
            color: "#fff",
            fontSize: "15px",
            fontWeight: 600,
            marginRight: "20px",
            borderRadius: "10px",
            cursor: "pointer",
            border: "none",
            textTransform: "capitalize",
            backgroundColor:
              tagsList.filter((tags) => tags.active).length > 0
                ? "grey"
                : "#F89828",
          }}
          onClick={() => handleFilterTags("All")}
        >
          All
        </button>
        {tagsList.map(
          (tag, i) =>
            i < 9 && (
              <button
                style={{
                  width: "max-content",
                  padding: "10px 25px",
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: 600,
                  marginRight: "20px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: tag.active ? "#F89828" : "grey",
                  whiteSpace: "nowrap",
                }}
                onClick={() => handleFilterTags(tag.value)}
              >
                {tag.value}
              </button>
            )
        )}
      </div>
    </div>
  );
}
