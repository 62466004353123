import { Link } from "react-router-dom";
import cx from "classnames";
import { scrollToTopSmooth } from "../../../../util";
import { useSelector, useDispatch } from "react-redux";

export default function Menus({ active = false, close = () => {}, menuData }) {
  const cart = JSON.parse(localStorage.getItem("cart"));

  const total = cart
    ? cart.reduce(function (sum, item) {
        return Number(sum) + Number(item.qty);
      }, 0)
    : 0;

  if (window.location.href.includes("hos-postcards")) {
    menuData = menuData.filter((menu) => menu.name !== "DESTINATIONS");
  }

  return (
    <>
      <span onClick={close} className={cx("menu-bg d-lg-none", { active })} />
      <div className={cx("menus", { active })}>
        <ul>
          {menuData &&
            menuData.map(({ name, link }, index) =>
              name === "DINING" ? (
                <li onClick={close} key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {name}
                  </a>
                </li>
              ) : (
                <li onClick={close} key={index}>
                  <Link to={link} onClick={scrollToTopSmooth}>
                    {name}
                  </Link>
                </li>
              )
            )}
        </ul>
      </div>
    </>
  );
}
