import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import { Component, useState } from "react";
import { Link } from "react-router-dom";
import { LineArrow } from "../../components/SvgComponents";
import headBanner from "../../assets/images/upload-banner.png";
import FaqComp from "react-faq-component";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const data = {
  rows: [
    {
      title: "What is the Spend & Win promotion?",
      content:
        "It is a limited time promotional offer by Shurooq where you can spend and win big. Simply spend AED 50 at any Shurooq destination and enter the raffle draw to win cash prizes up to AED 15,000.",
    },
    {
      title: "What are the cash prizes I can win?",
      content:
        "The cash prizes you can win after participating in the Spend & Win promotion are AED 15,000, AED 10,000, and AED 5,000.",
    },
    {
      title: "How do I participate in the Spend & Win promotion?",
      content:
        "If you spend AED 50 or above, you are eligible to participate in the Spend & Win offer. To participate simply upload the invoice on disovershurooq.ae or scan the QR code available on the communication materials at Shurooq destinations.",
    },
    {
      title:
        "Is there any other way to participate in the Spend & Win promotion?",
      content:
        "Yes. You can also participate in the Spend & Win raffle draw by dropping the voucher provided by the destination or outlet against AED 50 or more purchases. And for online purchases of AED 50 or more, you will get auto generated vouchers that you can submit to participate in the Spend & Win raffle draw.",
    },
    {
      title: "What are the destinations where I can Spend & Win?",
      content:
        "You can spend and win at the following Shurooq destinations and services: Al Majaz Waterfront, Al Montazah, Al Noor Island, Al Qasba, City Sightseeing Sharjah, Heart of Sharjah, Khorfakkan Beach, Mleiha, Sharjah Boats Tour, The Flag Island and The Flag Squares.",
    },
    {
      title:
        "Can I have multiple entries for the raffle draw of the Spend & Win promotion?",
      content:
        "Yes, you can have multiple raffle entries. Each AED 50 purchase with an original/scanned receipt will be eligible for one raffle entry. The total value can be in a single or cumulative purchase.",
    },
    {
      title: "What is the duration of the Spend & Win promotion?",
      content:
        "The Spend & Win promotion is valid from 1 July 2024 until 31 August 2024. Only purchases / invoices and participation made during this period of time will be eligible to enter the draw.",
    },
    {
      title: "When will the winners of Spend & Win promotion be announced?",
      content:
        "The winners of Spend & Win promotion raffle draw will be announced on 03 September 2024.",
    },
  ],
};

const About = () => (
  <div className="text-content">
    <p>
      Now you can spend & win big with Shurooq! Simply spend AED 50 at any
      Shurooq destination and enter the raffle draw to win up to AED 15,000. You
      can participate by uploading the invoice on disovershurooq.ae or scanning
      the QR code available on all communication materials.
      <br /> In cases of online purchases, you get auto generated vouchers for
      every AED 50 or more purchase on discovershurooq.ae that entitles you to
      participate in the Spend & Win raffle draw.
      <br /> For more updates on exciting experiences and promotions, follow us
      on Instagram
      <a
        href="https://www.instagram.com/discovershurooq/"
        target="_blank"
        rel="noreferrer noopener"
      >
        @discovershurooq
      </a>
    </p>
  </div>
);

const Terms = () => (
  <div className="text-content">
    <ul>
      {" "}
      <li>
        • The promotion is valid from 1 July 2024 – 31 August 2024. Only
        purchases and participation made within these dates will be eligible to
        enter the draw.
      </li>
      <li>
        • Each AED 50 purchase with an original/scanned receipt will be eligible
        for one raffle entry. The total value can be in a single or cumulative
        purchase.
      </li>
      <li>
        • Purchases must be made at outlets from the following Shurooq
        destinations and services: Al Majaz Waterfront, Al Montazah, Al Noor
        Island, Al Qasba, City Sightseeing Sharjah, Heart of Sharjah, Khorfakkan
        Beach, Mleiha, Sharjah Boats Tour, The Flag Island, The Flag Squares.
      </li>
      <li>
        • Customers can enter the draw by scanning their invoices online or
        submitting a voucher into the raffle box available in select restaurants
        and locations. Only one of the above-mentioned options can be selected
        per invoice.
      </li>
      <li>
        • The raffle draw ends on 30 August 2024 and the draw announcement will
        be made on 03 September 2024. The prizes are AED 15,000, AED 10,000, and
        AED 5,000
      </li>
      <li>• All dates are subject to change without prior notice.</li>
      <li>
        • The winners will be notified by phone and email within a week after
        the prize draw is held. If the winner does not respond to Shurooq within
        14 days of being notified, then the winner's prize will be forfeited and
        Shurooq will be entitled to select another winner in accordance with the
        process described above. The new winner will have to respond to the
        notification of their win within 14 days as well, or else their prize
        will be forfeited.
      </li>
      <li>
        • Participants can only win one prize regardless of multiple raffles
        they may accumulate.
      </li>
      <li>
        • If a winner rejects their prize, or the entry is invalid, or is
        breaching any of these Terms & Conditions, the winner's prize will be
        forfeited and Shurooq reserves the right to select another winner.
      </li>
      <li>
        • The management holds the right to announce the winner’s name on any
        media chosen by Shurooq and its affiliates.{" "}
      </li>
      <li>
        • A valid identification and official participation raffle ticket must
        be presented to claim the prize.
      </li>
      <li> • Prizes cannot be replaced or exchanged for cash or refunded.</li>
      <li>
        • The owners and employees of Shurooq and affiliate outlets, their
        suppliers and their immediate families are not eligible to participate
        in this promotion.
      </li>
      <li>
        • The management reserves the right to cancel or amend these Terms &
        Conditions at any time without prior notice.
      </li>
    </ul>
  </div>
);

const Faq = () => (
  <div className="text-content">
    <FaqComp data={data} />
  </div>
);

function UploadContent() {
  const btns = [
    { name: "About", id: 1 },
    { name: "Terms", id: 2 },
    { name: "Faq", id: 3 },
  ];
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="UploadContent">
      {/* <div className="tab-package-btn w-100">
        <div className="wrapper">
          {btns.map(({ name, active = false }) => (
            <button className={cx({ active: active })}>{name}</button>
          ))}
        </div>
      </div>
      <p>
        Get ready for an exciting off-road experience as you discover the most spectacular vistas
        Mleiha has to offer. Your journey will last approximately 6 hours as you take in views from
        two different peaks, visit ancient sites, stop for picture-perfect photos of the stunning
        landscape and conclude your adventure in the relaxing setting of our Sunset Lounge.
      </p> */}

      <div className="tab-package-btn">
        <div
          className=""
          style={{
            overflowX: "auto",
            display: "flex",
            paddingTop: 25,
            paddingBottom: 25,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          {btns.map(({ id, name }) => (
            <button
              key={id}
              className={cx("btn", { active: id === activeTab })}
              onClick={() => setActiveTab(id)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      <div className="content">
        {activeTab === 1 && <About />}
        {activeTab === 2 && <Terms />}
        {activeTab === 3 && <Faq />}
      </div>

      <div className="links" style={{ textAlign: "center" }}>
        <a
          href="/invoics"
          className="btn btn-primary w-25"
          style={{ margin: "10px" }}
        >
          Invoices
        </a>
        <a href="/all-raffles" className="btn btn-primary w-25">
          Raffles
        </a>
      </div>
    </div>
  );
}
class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDoneButton: false,
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: [],
    };
  }

  handleOnUpdate(name) {}
  componentDidMount() {
    const { token, history } = this.props;
    localStorage.setItem("pathname", history.location.pathname);
  }

  fileUploadToServer = () => {
    const { files } = this.state;
    const { uploadFile } = this.props;
    const data = new FormData();
    data.append("action", "uploadInvoice");
    data.append("filepond", files);
    uploadFile(data);
  };

  onFileUpload = (fileItems) => {
    this.setState(
      {
        files: fileItems.map((fileItem) => fileItem.file),
      },
      () => {
        this.fileUploadToServer();
      }
    );
  };
  handleInit() {
    this.setState({
      showDoneButton: true,
    });
  }

  render() {
    const { token, history } = this.props;
    const { showDoneButton } = this.state;

    return (
      <div>
        <UploadBanner
          linkPage={[
            { type: "link", name: "Home", link: "/" },
            { name: "Upload File" },
          ]}
          backgroundImage={headBanner}
          position={true}
        />
        <section className="uploadSection">
          <div className="UploadCard">
            <h4 className="text-center">Select Images to Upload</h4>

            <Row>
              <Col md={6}>
                <div className="UploadFile">
                  {this.props.token ? (
                    <div>
                      <FilePond
                        ref={(ref) => (this.pond = ref)}
                        files={this.state.files}
                        allowMultiple={true}
                        server={`https://api.discovershurooq.ae/api/?token=${token}&action=uploadInvoice`}
                        // oninit={() => this.handleInit()}
                        onload={() => this.handleOnUpdate()}
                        forceRevert={true}
                        onprocessfiles={() => this.handleInit()}
                        onupdatefiles={(fileItems) => {
                          // Set currently active file objects to this.state
                          this.setState({
                            files: fileItems.map((fileItem) => fileItem.file),
                          });
                        }}
                        labelIdle='Select Images to Upload Your Invoices - <span className="filepond--label-action">Browse</span>'
                      ></FilePond>
                    </div>
                  ) : (
                    <div className="fake-uploadBox">
                      {/* {this.props.t("Upload.select_image")} -{" "}
                      <span className="filepond--label-action">
                        {this.props.t("Upload.browse")}
                      </span> */}
                    </div>
                  )}

                  <div className="d-flex justify-content-center  uploadFloatButton">
                    {showDoneButton && (
                      <Button
                        className="w-50"
                        color="voucher-blue"
                        onClick={() => history.push("invoics")}
                      >
                        {this.props.t("Upload.done")}
                      </Button>
                    )}
                  </div>
                  {!this.props.token && (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 40,
                        marginBottom: 40,
                        justifyContent: "center",
                      }}
                    >
                      <div className="d-table mx-auto">
                        <Link
                          style={{ width: 220 }}
                          className="btn btn-primary arrow"
                          to="/login"
                        >
                          <span>
                            <LineArrow />
                            Login to Upload Invoice
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <UploadContent />
              </Col>
            </Row>

            <Row>
              <img
                className="upload-banner-image"
                src="https://bo.discovershurooq.ae/files/S&W-logo61895267b1835.png"
                style={{ margin: "auto", display: "none" }}
              />
            </Row>
          </div>
        </section>
      </div>
    );
  }
}

function App(props) {
  const t = useTranslation();
  return <Upload t={t} {...props} />;
}
const mapStateToProps = ({ user: { loginData, loginStatus, token } }) => ({
  loginData,
  loginStatus,
  token,
});

const mapDispatchToProps = {
  uploadFile: userAction.uploadFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
