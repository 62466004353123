import React, { useState, useEffect, useMemo } from "react";
import "./style1.scss";
import defaultImage from "../../../src/assets/images/default-image.jpg";
import { Link } from "react-router-dom";
import shoppingBag from "../../assets/images/icons/shopping-bag.svg";
import placeholder from "../../assets/images/placeholder.png";

export default function CategoryList(props) {
  const product = props.product;
  return (
    // <Link
    //   to={product.link}
    //   style={{
    //     textDecoration: "none",
    //     color: "inherit",
    //   }}
    // >
    //   <div
    //     className="category-grid"
    //     style={{
    //       background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${product.img})`,
    //     }}
    //   >
    //     <h6
    //       style={{
    //         fontSize: "20px",
    //         fontWeight: 600,
    //         color: "#fff",
    //       }}
    //     >
    //       {product.title}
    //     </h6>
    //   </div>
    // </Link>
    <Link
      to={product.link}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <div className="book-SlideCard">
        <div className="wrapper">
          <img className="render-image" src={product.img} alt="product-img" />
          <span className="dark-layer" />
          <div className="content">
            <div>
              <h6
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#fff",
                }}
              >
                {product.title}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
