import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { setCartData } from "../../actions/cart-action-type";
import TrustedLogo from "../../../src/assets/images/logos/trusted.svg";
import filter from "../../../src/assets/images/icons/filter.svg";
import innerdesignersbanner from "../../../src/assets/images/innerdesignersbanner.jfif";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import ProductList from "../../components/productList";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import Filters from "../../components/filters";
import CollectionBanner from "../../components/banners/CollectionBanner";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import TagsFilter from "../../components/TagsFilter";

const Designer = () => {
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [tempCollectionProducts, setTempCollectionProducts] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [designerProduct, setDesignerProduct] = useState();
  const params = useParams();

  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyFormData = new FormData();
    bodyFormData.append("action", "listCollections");
    bodyFormData.append("destination_slug", params.slug);
    window.scrollTo({ behavior: "smooth", top: 0 });

    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      console.log(res.data);
      setDesignerProduct(
        res.data.data.find((d) => d.slug === params.designer_slug)
      );
    });

    const formData = new FormData();
    formData.append("action", "listProductsBySlug");
    formData.append("destination_slug", params.slug);
    formData.append("category_slug", params.designer_slug);

    window.scrollTo({ behavior: "smooth", top: 0 });

    axios.post(process.env.REACT_APP_BASE_URL, formData).then((res) => {
      console.log(res.data);
      setCollectionProducts(
        Object.keys(res.data.data).map((k) => res.data.data[k])
      );
      setTempCollectionProducts(
        Object.keys(res.data.data).map((k) => res.data.data[k])
      );
      setLoading(false);
    });
  }, []);

  const handleFilterTags = (selectedTag) => {
    if (selectedTag === "All") {
      setTagsList((prev) => {
        prev.map((list) => {
          list.active = false;
          return list;
        });
        return prev;
      });
      setCollectionProducts(tempCollectionProducts);
    } else {
      setTagsList((prev) => {
        prev.map((list) => {
          if (list.value === selectedTag) {
            list.active = true;
          } else {
            list.active = false;
          }
          return list;
        });
        return prev;
      });
      const filteredCollections = tempCollectionProducts.filter(
        (temp) =>
          temp.tags && temp.tags.find((tag) => tag.value === selectedTag)
      );
      setCollectionProducts(filteredCollections);
    }
  };

  const renderLoader = () => (
    <Row className="m-0">
      {["", "", "", "", "", "", "", ""].map((data, index) => (
        <Col className="mb-4" lg={3} md={6}>
          <ContentLoader
            speed={2}
            viewBox="0 0 520 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="1" y="0" rx="3" ry="3" width="700" height="1000" />
          </ContentLoader>
        </Col>
      ))}
      ;
    </Row>
  );

  return (
    <div className="productdetail">
      <StoreMenu />
      <CollectionBanner
        backgroundImage={innerdesignersbanner}
        titleArr={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: params.designer_slug,
            link: `/stores/gsharshop/designers/${params.designer_slug}`,
          },
        ]}
        designerProduct={designerProduct}
      />

      <div className="container py-5">
        <Row className="m-3">
          {collectionProducts.length > 0 && (
            <TagsFilter
              tempProducts={tempCollectionProducts}
              setProducts={setCollectionProducts}
            />
          )}
        </Row>
        {!loading ? (
          <Row className="m-0">
            {collectionProducts.map(
              (collectionProduct) =>
                Number(collectionProduct.qty) > 0 && (
                  <Col
                    className="mb-4"
                    key={collectionProduct.product_id}
                    lg={3}
                    md={6}
                  >
                    <ProductList product={collectionProduct}></ProductList>
                  </Col>
                )
            )}
          </Row>
        ) : (
          renderLoader()
        )}
      </div>
      {!collectionProducts.length && !loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "4rem",
            fontSize: 30,
            fontWeight: 600,
          }}
        >
          No products Found
        </div>
      )}
    </div>
  );
};

export default connect()(withRouter(Designer));
