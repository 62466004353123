import ushna from "../../../assets/images/logos/ushna.png";
import Illy from "../../../assets/images/logos/Illy.png";

const offerData = [
  {
    logo: Illy,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  },
  {
    logo: ushna,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  },
  {
    logo: Illy,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  },
  {
    logo: ushna,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  },
  {
    logo: Illy,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  },
  {
    logo: ushna,
    name: "Babu Town",
    title: "50% Off",
    decription: "Order Babu Town at Home. Book with Talabat for 50% Off",
    validTill: "Aug-31",
  },
];

export default offerData;
