import React, { useEffect, useState } from "react";
import AboutBanner from "../../components/banners/AboutBanner";
import { Row, Col, Container } from "reactstrap";
import { connect, useSelector } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import PostCardsBanner from "../../components/banners/PostCardsBanner";
import banner from "../../assets/temp/postcards-bg.jpg";

const AboutHosCards = (props) => {
  document.title = "About Us - HosCards";

  return (
    <div>
      <PostCardsBanner
        linkPage={[
          { type: "link", name: "Home", link: "/hos-postcards" },
          { name: "About Us" },
        ]}
        backgroundImage={banner}
        // destinationName="Share your moments"
        // destinationDescription=" "
        // destinationHoverDescription="Share your precious moments with friends and family as postcards through Emirates Post. Once created, you can download/print the postcards and drop it at your nearest Emirates Post box."
      />
      <section className="uploadSection">
        <div className="UploadCard" style={{ background: "#f9f9fa" }}>
          <div>
            <Container fluid>
              <h2 className="pb-3">
                From Heart of Sharjah to the world. A great way to share your
                moments
              </h2>
              <p>
                The personalized Heart of Sharjah postcards is a great way to
                share memories with friends and family about precious moments
                spent at Heart of Sharjah. This initiative is the result of a
                collaboration with Emirates Post that allows visitors to
                personalize their postcards by adding their picture and custom
                message.
              </p>

              <p>
                The postcards feature pictures of various landmarks from the
                Heart of Sharjah taken by Hamid Durrani - one of the first
                photographers in Sharjah. The postcards can be purchased as
                souvenirs or sent to friends and family in any part of the
                world.
              </p>

              <p>Enjoy sharing your moments with Heart of Sharjah postcards!</p>

              {/* <h5>Newsletter:</h5>
              <p>
                Sign up to get the latest offers and events right to your inbox!
              </p>
              <a href="/register" className="btn btn-primary">
                Signup
              </a> */}
            </Container>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { raffleDataByInvoice } }) => ({});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AboutHosCards));
