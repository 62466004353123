import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import logo from "../../../assets/images/logo2.png";
import cx from "classnames";
import Menus from "./menus";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../../actions/user-action-type";
import { useHistory } from "react-router";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import $ from "jquery";
import "./style.scss";
import { scrollToTopSmooth } from "../../../util";
import { Button } from "reactstrap";
import { LineArrow } from "../../SvgComponents";
import globalConfig from "../../../globalConfig";
import bag from "../../../assets/images/shopping-cart-10903.svg";
import { Helmet } from "react-helmet";

function MenuIcon() {
  return (
    <svg
      fill="#fff"
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="menu"
      width="1em"
      height="1em"
      aria-hidden="true"
    >
      <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
    </svg>
  );
}

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [menu, setMenu] = useState(false);
  const [isEnglish, defaultLanguage] = useState(true);
  const storeData = useSelector((store) => store);

  const handleScroll = () => {
    setIsSticky(() => (window.scrollY > 70 ? true : false));
  };
  const path = localStorage.pathname;
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const onLogOut = () => {
    dispatch(logOut());
    history.push("/");
  };

  const myAccountPage = () => {
    history.push("/settings");
  };

  const menuData = [
    {
      name: "HOME",
      link: window.location.href.includes("hos-postcards")
        ? "/hos-postcards"
        : "/",
    },
    { name: "DESTINATIONS", link: "/view-all-destination" },

   // { name: "DINING", link: "https://discovershurooq.ae/F&B" },

{
      name: "ABOUT ",
      link: window.location.href.includes("hos-postcards")
        ? "/hos-postcards-about"
        : "/aboutus",
    },
    {
      name: "CONTACT",
      link: window.location.href.includes("hos-postcards")
        ? "/hos-postcards-contact"
        : "/contactus",
    },
    // { name: `CART`, link: "/cart" },
    // { name: "STORES", link: "/stores" },
  ];

  useEffect(() => {
    const lang = localStorage.getItem("user_language");
    if (lang === null) {
      localStorage.setItem("user_language", "en");
      setLanguage("en");
      defaultLanguage(true);
      $("html").attr("dir", "ltr");
      $("html").attr("lang", "en");
    }
    if (lang === "en") {
      setLanguage("en");
      defaultLanguage(true);
      $("html").attr("lang", "en");
      $("html").attr("dir", "ltr");
    }
    if (lang === "ae") {
      setLanguage("ar");
      defaultLanguage(false);
      $("html").attr("lang", "ar");
      $("html").attr("dir", "rtl");
    }
  }, []);

  const updateLang = async () => {
    const lang = await localStorage.getItem("user_language");
    if (lang === null) {
      await localStorage.setItem("user_language", "en");
      setLanguage("en");
      defaultLanguage(true);
      $("html").attr("lang", "en");
      $("html").attr("dir", "ltr");
    }
    if (lang === "en") {
      await localStorage.setItem("user_language", "ae");
      setLanguage("ar");
      defaultLanguage(false);
      $("html").attr("lang", "ar");
      $("html").attr("dir", "rtl");
    }
    if (lang === "ae") {
      await localStorage.setItem("user_language", "en");
      setLanguage("en");
      defaultLanguage(true);
      $("html").attr("lang", "en");
      $("html").attr("dir", "ltr");
    }
  };

  const noOfQuantity = () => {
    let cart = JSON.parse(localStorage.getItem("products"));
    var total = 0;
    cart?.map((product) => {
      total = product.qty + total;
    });
    return total;
  };

  return (
    <>
      <Helmet></Helmet>

      {!globalConfig.isStorePage && (
        <header
          id="header"
          className={cx(true)}
          style={{
            backgroundColor: isSticky && "#000000b3",
          }}
        >
          <Container fluid="xl">
            <div className="logo">
              <Link to="/" onClick={scrollToTopSmooth}>
                <img src={logo} height="100px" alt="logo" />
              </Link>
            </div>
            <Menus
              active={menu}
              close={() => setMenu(false)}
              menuData={menuData}
            />
            <div
              className="links"
              style={{ display: "flex", alignItems: "center" }}
            >
              {noOfQuantity() > 0 && (
                <Link
                  to={`/stores/gsharshop/cart`}
                  style={{ fontSize: "20px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-cart4"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                  </svg>
                  {/* <i className="fa fa-shopping-cart" aria-hidden="true"></i> */}
                  <span class="badge badge-warning" id="lblCartCount">
                    {noOfQuantity()}
                  </span>
                </Link>
              )}
              {storeData.user.token ? null : (
                <Link
                  to="/register"
                  onClick={scrollToTopSmooth}
                  style={{ marginTop: 5 }}
                >
                  Register
                </Link>
              )}
              {/* {storeData.user.token ? null : <Button color="" className="arrow" onClick={() => history.push(`/register`)}>
            <span>
              Register
              <LineArrow />
            </span>
          </Button>} */}
              {storeData.user.token ? (
                // <Link to="/settings" onClick={scrollToTopSmooth} className="btn btn-primary">
                //   My Account
                // </Link>
                <Button
                  color="primary"
                  style={{ width: 140 }}
                  className="arrow"
                  onClick={() => history.push(`/settings`)}
                >
                  <span>
                    My Account
                    <LineArrow />
                  </span>
                </Button>
              ) : (
                // <Link to="/login" onClick={scrollToTopSmooth} className="btn btn-primary">
                //   Sign in
                // </Link>
                <Button
                  color="primary"
                  style={{ width: 100 }}
                  className="arrow"
                  onClick={() => history.push(`/login`)}
                >
                  <span>
                    Sign in
                    <LineArrow />
                  </span>
                </Button>
              )}

              <button
                onClick={() => setMenu(true)}
                className="menu-btn d-lg-none"
              >
                <MenuIcon />
              </button>
            </div>
          </Container>
        </header>
      )}
    </>
  );
}
export default Header;
