import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import defaultImage from "../../../src/assets/images/default-image.jpg";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import shoppingBag from "../../assets/images/icons/shopping-bag.svg";
import placeholder from "../../assets/images/placeholder.png";

export default function ProductList(props) {
  const product = props.product;
  return (
    <Link
      to={`/stores/gsharshop/product_detail/${product.slug}`}
      className="product-link-image"
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <div className="product-grid">
        <div className="product-image">
          <LazyLoad height={200}>
            <img
              placeholder={placeholder}
              className="pic-1"
              alt={product.product_title}
              src={
                JSON.parse(product.images)
                  ? JSON.parse(product.images)[0]
                  : defaultImage
              }
            />
          </LazyLoad>

          {/* <a className="btn btn-xs product-links d-flex align-items-center justify-content-center">
            <i className="far fa-eye me-2"></i>
            Quick View
          </a> */}
        </div>

        <div className="product-content">
          <h3 className="title">
            <a href="#" style={{ whiteSpace: "nowrap" }}>
              {product.product_title.substring(0, 20)}
              {product.product_title.length > 20 && "..."}
            </a>
          </h3>
          <div className="price">
            <div>AED {product.price}</div>
            <div></div>
          </div>
        </div>
      </div>
    </Link>
  );
}
