import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormGroup, Input, Row, Col, Label } from "reactstrap";
import manIcon from "../../../assets/images/icons/ion_man-outline.svg";
import childIcon from "../../../assets/images/icons/la_child.svg";
import { AddIcon, MinusIcon, PlusIcon, SubIcon } from "../../SvgComponents";
import packagesData from "../../../translations/package.json";
import RadioInput from "../../banners/RadioInput";

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 22H18V20C18 19.2044 17.6839 18.4413 17.1213 17.8787C16.5587 17.3161 15.7956 17 15 17H9C8.20435 17 7.44129 17.3161 6.87868 17.8787C6.31607 18.4413 6 19.2044 6 20V22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13ZM12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11Z"
      fill="#9FA0A0"
    />
  </svg>
);
const ChildrenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 20H19V22H5V20ZM12 18C9.87827 18 7.84344 17.1571 6.34315 15.6569C4.84285 14.1566 4 12.1217 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10C20 12.1217 19.1571 14.1566 17.6569 15.6569C16.1566 17.1571 14.1217 18 12 18ZM12 16C13.5913 16 15.1174 15.3679 16.2426 14.2426C17.3679 13.1174 18 11.5913 18 10C18 8.4087 17.3679 6.88258 16.2426 5.75736C15.1174 4.63214 13.5913 4 12 4C10.4087 4 8.88258 4.63214 7.75736 5.75736C6.63214 6.88258 6 8.4087 6 10C6 11.5913 6.63214 13.1174 7.75736 14.2426C8.88258 15.3679 10.4087 16 12 16Z"
      fill="#9FA0A0"
    />
  </svg>
);

const InputComponent = (props) => {
  const { name = "", value = "" } = props;
  return (
    <FormGroup>
      <Input name={name} value={value} onChange={() => {}} />
    </FormGroup>
  );
};

const Users = (props) => {
  const {
    setNumPerson,
    adult_price,
    child_price,
    addPerson,
    addChild,
    setCustomChildValue,
    packageDetails_id,
  } = props;
  console.log("addperson==", addPerson);
  const params = useParams();
  const [num_adult, setNumAdult] = useState(addPerson);
  const [num_child, setNumChild] = useState(addChild);
  const [adultLimitMax, setAdultLimitMax] = useState(99);
  const [adultLimitMin, setAdultLimitMin] = useState(0);
  const [childLimitMax, setChildLimitMax] = useState(99);
  const [childLimitMin, setChildLimitMin] = useState(0);
  const [childValues, setChildValues] = useState([]);
  const [child, setChild] = useState([]);

  useEffect(() => {
    if (packageDetails_id === "2501") {
      setChildValues([
        { label: "All Days", price: 290, checked: true },
        { label: "18 Aug: Videos Lab", price: 80, checked: false },
        { label: "19 Aug: Photos Lab", price: 80, checked: false },
        { label: "20 Aug: Art Lab", price: 80, checked: false },
        {
          label: "21 Aug: Animations Lab",
          price: 80,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2502") {
      setChildValues([
        { label: "All Days", price: 744, checked: true },
        {
          label: "25 Aug: Emotional Release",
          price: 276,
          checked: false,
        },
        {
          label: "26 Aug: Conscious Relationships",
          price: 252,
          checked: false,
        },
        {
          label: "27 Aug: Word to Art-Painting Therapy",
          price: 252,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2503") {
      setChildValues([
        { label: "All Days", price: 720, checked: true },
        {
          label: "4 Aug: Resin Art with Flowers Workshop",
          price: 185,
          checked: false,
        },
        {
          label: "5 Aug: Jesmonite Terrazzo Workshop",
          price: 185,
          checked: false,
        },
        {
          label: "6 Aug: Concrete Cement Art",
          price: 185,
          checked: false,
        },
        {
          label: "7 Aug: Boho-style Painting on Tote Bags",
          price: 185,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2504") {
      setChildValues([
        { label: "All Days", price: 780, checked: true },
        {
          label: "11 Aug: Create a Viral Tiktok/Reel Video",
          price: 205,
          checked: false,
        },
        {
          label: "12 Aug: Portrait Photography with Natural/Studio Lights",
          price: 205,
          checked: false,
        },
        {
          label: "13 Aug: Capture Beautiful Sunset Photos",
          price: 205,
          checked: false,
        },
        {
          label: "14 Aug: E-commerce/Products Photography",
          price: 205,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2505") {
      setChildValues([
        { label: "All Days", price: 360, checked: true },
        {
          label: "22 Aug: Automation with Arduino Coding",
          price: 130,
          checked: false,
        },
        {
          label: "23 Aug: Automation with Arduino Coding",
          price: 130,
          checked: false,
        },
        {
          label: "24 Aug: Automation with Arduino Coding",
          price: 130,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2596") {
      setChildValues([
        { label: "All Days", price: 780, checked: true },
        {
          label:
            "8 September 2022: Create a Viral TikTok/Reel Video - Per Session",
          price: 205,
          checked: false,
        },
        // {
        //   label:
        //     "9 September 2022: Portrait Photography with Natural/Studio Lights - Per Session",
        //   price: 205,
        //   checked: false,
        // },
        {
          label:
            "10 September 2022: Capture Beautiful Sunset Photos - Per Session",
          price: 205,
          checked: false,
        },
        {
          label:
            "11 September 2022: E- commerce/Products Photography - Per Session",
          price: 205,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2595") {
      setChildValues([
        { label: "All Days", price: 290, checked: true },
        {
          label: "1-day (2 sessions) Package fees",
          price: 160,
          checked: false,
        },
        {
          label: "3 September 2022: Photos Lab - Fees per session",
          price: 80,
          checked: false,
        },
        {
          label: "3 September 2022: Videos Lab - Fees per session",
          price: 80,
          checked: false,
        },
        {
          label: "4 September 2022: Art Lab - Fees per session",
          price: 80,
          checked: false,
        },
        {
          label: "4 September 2022: Animations Lab - Fees per session",
          price: 80,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2633") {
      setChildValues([
        { label: "Full Program", price: 450, checked: true },
        {
          label: "Day Pass (October 2)",
          price: 100,
          checked: false,
        },
        {
          label: "Day Pass (October 9)",
          price: 100,
          checked: false,
        },
        {
          label: "Day Pass (October 16)",
          price: 100,
          checked: false,
        },
        {
          label: "Day Pass (October 23)",
          price: 100,
          checked: false,
        },
        {
          label: "Day Pass (October 30)",
          price: 100,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2634") {
      setChildValues([
        { label: "Full Program", price: 200, checked: true },
        {
          label: "Day Pass (October 3)",
          price: 75,
          checked: false,
        },
        {
          label: "Day Pass (October 4)",
          price: 75,
          checked: false,
        },
        {
          label: "Day Pass (October 5)",
          price: 75,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "749") {
      setChildValues([
        { label: "Single Session", price: 50, checked: true },
        // {
        //   label: "Monthly subscription (4 sessions)",
        //   price: 180,
        //   checked: false,
        // },
      ]);
    } else if (packageDetails_id === "2734") {
      setChildValues([
        { label: "All 4 themes", price: 200, checked: true },
        {
          label: "Theme: Culture - Friday 11th November 2022",
          price: 75,
          checked: false,
        },
        {
          label: "Theme: Architecture - Friday 18th November 2022",
          price: 75,
          checked: false,
        },
        {
          label: "Theme: Wildlife - Friday 25th November 2022",
          price: 75,
          checked: false,
        },
        {
          label: "Theme: Astrophotography - Friday 2nd December 2022",
          price: 75,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "2807") {
      setChildValues([
        { label: "Individual ", price: 35, checked: true },
        {
          label: "Family package (4 tickets)",
          price: 100,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3021") {
      setChildValues([
        { label: "All Days", price: 500, checked: true },
        {
          label: "7 January 2023",
          price: 150,
          checked: false,
        },
        {
          label: "14 January 2023",
          price: 150,
          checked: false,
        },
        {
          label: "21 January 2023",
          price: 150,
          checked: false,
        },
        {
          label: "28 January 2023",
          price: 150,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3092") {
      setChildValues([
        { label: "Per Hour", price: 250, checked: true },
        {
          label: "2 hours",
          price: 450,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3104") {
      setChildValues([
        { label: "Per Hour", price: 150, checked: true },
        {
          label: "2 hours",
          price: 250,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3093") {
      setChildValues([
        { label: "Per Day", price: 30, checked: true },
        {
          label: "Per Month",
          price: 150,
          checked: false,
        },
        {
          label: "Four Months",
          price: 650,
          checked: false,
        },
        {
          label: "Per Year",
          price: 1500,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3096") {
      setChildValues([
        { label: "Per Day", price: 30, checked: true },
        {
          label: "Per Month",
          price: 150,
          checked: false,
        },
        {
          label: "Four Months",
          price: 650,
          checked: false,
        },
        {
          label: "Per Year",
          price: 1500,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3094") {
      setChildValues([
        { label: "WeekDays", price: 200, checked: true },
        {
          label: "Weekends",
          price: 250,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3097") {
      setChildValues([
        { label: "WeekDays", price: 200, checked: true },
        {
          label: "Weekends",
          price: 250,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3643" || packageDetails_id === "3644") {
      setChildValues([
        { label: "30 mins", price: 45, checked: true },
        {
          label: "60 mins",
          price: 135,
          checked: false,
        },
        {
          label: "90 mins",
          price: 180,
          checked: false,
        },
        {
          label: "120 mins",
          price: 240,
          checked: false,
        },
      ]);
    } else if (packageDetails_id === "3123") {
      setChildValues([
        { label: "1 month (Non-UAE Residents)", price: 300, checked: true },
        {
          label: "3 months",
          price: 900,
          checked: false,
        },
        {
          label: "6 months",
          price: 1500,
          checked: false,
        },
        {
          label: "12 months",
          price: 2500,
          checked: false,
        },
      ]);
    }
  }, [packageDetails_id]);

  useEffect(() => {
    const checkedValues = childValues.filter((ch) => ch.checked);
    setChild(checkedValues);
    setCustomChildValue(checkedValues);
  }, [childValues]);

  function minusPerson(title) {
    if (title === "Adult") {
      if (num_adult === adultLimitMin) {
        return;
      }
      setNumPerson(num_adult - 1, num_child);
      setNumAdult(num_adult - 1);
    } else {
      if (num_child === childLimitMin) {
        return;
      }
      setNumChild(num_child - 1);
      setNumPerson(num_adult, num_child - 1);
    }
  }
  function plusPerson(title) {
    if (title === "Adult") {
      if (num_adult === adultLimitMax) {
        return;
      }
      setNumPerson(num_adult + 1, num_child);
      setNumAdult(num_adult + 1);
    } else {
      if (num_child === childLimitMax) {
        return;
      }
      setNumPerson(num_adult, num_child + 1);
      setNumChild(num_child + 1);
    }
  }

  useEffect(() => {
    setNumAdult(addPerson);
    setNumChild(addChild);
  }, [addPerson, addChild]);

  const handleRadioChange = (value) => {
    if (value === "0") {
      childValues.map((ch, id) => {
        if (!id) {
          ch.checked = true;
        } else {
          ch.checked = false;
        }
      });
      setChildValues([...childValues]);
    } else {
      childValues.map((ch, id) => {
        if (!id) {
          ch.checked = false;
        } else if (id === Number(value)) {
          ch.checked = !ch.checked;
        }
      });
      setChildValues([...childValues]);
    }
  };

  const filedRender = ({ icon, title, limit, value, price }) => (
    <>
      <div className="render-input">
        <div className="n-wrapper">
          {icon === "Adult" ? <UserIcon /> : <ChildrenIcon />}
          {/* <img src={icon} alt={title} /> */}
          <span className="name">
            {title}(
            {packageDetails_id === "2501" ||
            packageDetails_id === "2502" ||
            packageDetails_id === "2503" ||
            packageDetails_id === "2504" ||
            packageDetails_id === "2505" ||
            packageDetails_id === "2596" ||
            packageDetails_id === "2595" ||
            packageDetails_id === "2633" ||
            packageDetails_id === "2634" ||
            packageDetails_id === "2734" ||
            packageDetails_id === "2807" ||
            packageDetails_id === "749" ||
            packageDetails_id === "3021" ||
            packageDetails_id === "3092" ||
            packageDetails_id === "3104" ||
            packageDetails_id === "3093" ||
            packageDetails_id === "3096" ||
            packageDetails_id === "3094" ||
            packageDetails_id === "3097" ||
            packageDetails_id === "3643" ||
            packageDetails_id === "3644" ||
            packageDetails_id === "3123"
              ? child.reduce(function (sum, item) {
                  return Number(sum) + Number(item.price);
                }, 0)
              : price}
            ) <small>AED</small>
          </span>
        </div>
        <div className="h-wrapper">
          <button onClick={() => minusPerson(icon)}>
            <SubIcon />
          </button>
          <span className="value">{value}</span>
          <button onClick={() => plusPerson(icon)}>
            <PlusIcon />
          </button>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="usertab">
        <Row>
          {adult_price !== "" && (
            <Col sm={6} className="mb-4">
              {filedRender({
                icon: "Adult",
                title:
                  packagesData.packages.find(
                    (pack) => pack.id === packageDetails_id
                  )?.adult_label || "Adult",
                limit: ">18 years",
                value: num_adult,
                price: adult_price,
              })}
            </Col>
          )}
          {child_price !== "" && (
            <Col sm={6} className="mb-4">
              {filedRender({
                icon: "Children",
                title:
                  packagesData.packages.find(
                    (pack) => pack.id === packageDetails_id
                  )?.child_label || "Children",
                limit: "<18 years",
                value: num_child,
                price: child_price,
              })}
            </Col>
          )}
          {(packageDetails_id === "2501" ||
            packageDetails_id === "2502" ||
            packageDetails_id === "2503" ||
            packageDetails_id === "2504" ||
            packageDetails_id === "2505" ||
            packageDetails_id === "2596" ||
            packageDetails_id === "2595" ||
            packageDetails_id === "2633" ||
            packageDetails_id === "2634" ||
            packageDetails_id === "2734" ||
            packageDetails_id === "2807" ||
            packageDetails_id === "749" ||
            packageDetails_id === "3021" ||
            packageDetails_id === "3092" ||
            packageDetails_id === "3104" ||
            packageDetails_id === "3093" ||
            packageDetails_id === "3096" ||
            packageDetails_id === "3094" ||
            packageDetails_id === "3097" ||
            packageDetails_id === "3643" ||
            packageDetails_id === "3644" ||
            packageDetails_id === "3123") && (
            <>
              <Col sm={12} className="mb-4">
                <div className="render-input" style={{ display: "block" }}>
                  {childValues.map((value, id) => {
                    return (
                      <>
                        <div className="theme-radio">
                          <FormGroup check>
                            <Label check>
                              <Input
                                onChange={(e) =>
                                  handleRadioChange(e.target.value)
                                }
                                value={id}
                                type="checkbox"
                                checked={value.checked}
                              />
                              <span />
                              {value.label} : {value.price} {"AED"}
                            </Label>
                          </FormGroup>
                        </div>
                        {((packageDetails_id === "2595" && (!id || id === 1)) ||
                          ((packageDetails_id === "2596" ||
                            packageDetails_id === "2634" ||
                            packageDetails_id === "2633") &&
                            !id)) && (
                          <hr style={{ backgroundColor: "black" }}></hr>
                        )}
                      </>
                    );
                  })}
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default Users;
