import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./style.scss";
import OwlCarousel from "react-owl-carousel2";
import frame1 from "../../assets/temp/frame1.png";
import frame2 from "../../assets/temp/frame2.png";
import frame3 from "../../assets/temp/frame3.png";
import full1 from "../../assets/temp/full1.png";
import full2 from "../../assets/temp/full2.png";
import full3 from "../../assets/temp/full3.png";
import full4 from "../../assets/temp/full4.png";
import full5 from "../../assets/temp/full5.png";
import banner from "../../assets/temp/postcards-bg.jpg";
import globalConfig from "../../globalConfig";
import ContentLoader from "react-content-loader";
import PostCardsBanner from "../../components/banners/PostCardsBanner";

const HosCards = () => {
  const history = useHistory();
  const options = {
    nav: true,
    loop: false,
    autoplay: false,
    margin: 20,
    dots: false,
    navText: [
      '<img src="https://img.icons8.com/ios/48/ffffff/circled-chevron-left.png"/>',
      '<img src="https://img.icons8.com/ios/48/ffffff/circled-chevron-right.png"/>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      767: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1600: {
        items: 3,
      },
    },
  };

  const [frameData, setFrameData] = useState([]);

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };
  localStorage.setItem("pathname", history.location.pathname);

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    localStorage.setItem("postImage", "");
    const bodyFormData = new FormData();
    bodyFormData.append("action", "postCards");
    axios
      .post(process.env.REACT_APP_BASE_URL, bodyFormData, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        setFrameData(res.data.data.sort((a, b) => b.id - a.id));
      });
  }, []);

  const handleNonCustomizable = (nonCustomframe) => {
    localStorage.setItem("postCard", nonCustomframe.image_base64);
    localStorage.setItem("posCardId", nonCustomframe.id);
    localStorage.setItem("postCardPrice", nonCustomframe.price);
    localStorage.setItem("postImage", "");
    history.push("/hos-postcards/message");
  };

  const renderLoader = () => (
    <OwlCarousel options={options}>
      {["", "", "", "", "", "", "", "", ""].map((data, index) => (
        <div key={index}>
          <ContentLoader
            speed={2}
            // width={638}
            // height={420}
            viewBox="0 0 638 520"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="1" y="0" rx="3" ry="3" width="638" height="480" />
            {/* <rect x="19" y="396" rx="3" ry="3" width="128" height="15" />
            <rect x="21" y="433" rx="3" ry="3" width="410" height="6" />
            <rect x="28" y="449" rx="3" ry="3" width="380" height="6" />
            <rect
              x="430"
              y="482"
              rx="3"
              ry="3"
              width="178"
              fill="red"
              height="37"
            /> */}
          </ContentLoader>
        </div>
      ))}
    </OwlCarousel>
  );

  return (
    <div className="productdetail">
      <PostCardsBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Postcards" },
        ]}
        backgroundImage={banner}
        destinationName="Share your moments"
        destinationDescription=" "
        destinationHoverDescription="Share your precious moments with friends and family as postcards through Emirates Post. Once created, you can download/print the postcards and drop it at your nearest Emirates Post box."
      />
      <div className="container py-5">
        {/* <div
          style={{
            fontSize: 40,
            marginBottom: "2rem",
          }}
        > */}
        <h2>Customized Postcard</h2>
        <p
          style={{
            marginBottom: "1rem",
          }}
        >
          Create a personalized postcard by using the available templates in
          just a few minutes.{" "}
        </p>
        {/* </div> */}
        <Row className="cards">
          {frameData.length > 0 ? (
            <OwlCarousel options={options}>
              {frameData.map(
                (frame) =>
                  frame.customisable === "1" && (
                    <Link to={`/hos-postcards/edit-my-card/${frame.id}`}>
                      <div className="hoscards-SlideCard">
                        <div className="wrapper">
                          <img
                            className="render-image"
                            src={`https://bo.discovershurooq.ae/cdn-cgi/image/width=680,height=400,quality=100/files/${frame.image}`}
                            alt={frame.name}
                          />
                          <span className="dark-layer" />
                          <h3 className="price">AED {frame.price}</h3>
                          <div className="content">
                            <div>
                              <h3 className="title">{frame.name}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
              )}
            </OwlCarousel>
          ) : (
            renderLoader()
          )}
        </Row>
        {/* <div
          style={{
            fontSize: 40,
            marginBottom: "2rem",
          }}
        > */}
        <h2>Heart of Sharjah Postcards</h2>
        <p
          style={{
            marginBottom: "1rem",
          }}
        >
          Share your experience of Heart of Sharjah with wonderful pictures
          postcards.
        </p>
        {/* </div> */}
        <Row className="cards">
          {frameData.length > 0 ? (
            <OwlCarousel options={options}>
              {frameData.map(
                (frame) =>
                  frame.customisable === "0" && (
                    <div
                      className="hoscards-SlideCard"
                      onClick={() => handleNonCustomizable(frame)}
                    >
                      <div className="wrapper">
                        <img
                          className="render-image"
                          src={`https://bo.discovershurooq.ae/cdn-cgi/image/width=680,height=400,quality=100/files/${frame.image}`}
                          alt={frame.name}
                        />
                        <span className="dark-layer" />
                        <h3 className="price">AED {frame.price}</h3>
                        <div className="content">
                          <div>
                            <h3 className="title">{frame.name}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </OwlCarousel>
          ) : (
            renderLoader()
          )}
        </Row>
      </div>
    </div>
  );
};

export default connect()(withRouter(HosCards));
