import React, { useEffect, useState } from "react";
import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import * as userAction from "../../actions/user-action-type";
import { withRouter, useParams } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import ArrowIcon from "../../assets/images/icons/left-arrow.svg";
import moment from "moment";
import globalConfig from "../../globalConfig";
import StoreMenu from "../../components/layout/header/store-menu";

const OrderDetails = () => {
  const params = useParams();
  const [orderDetails, setOrderDetails] = useState();
  const history = useHistory();

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("jwt"),
  };
  localStorage.setItem("pathname", history.location.pathname);

  useEffect(() => {
    globalConfig.isStorePage = true;
    window.scrollTo({ behavior: "smooth", top: 0 });
    const data = new FormData();
    data.append("action", "listeComOrders");
    axios
      .post(process.env.REACT_APP_BASE_URL, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data.data);
        setOrderDetails(
          res.data.data.find(
            (order) => order.order_number === params.order_number
          )
        );
      });
  }, []);

  return (
    <div>
      <StoreMenu />
      <UploadBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Order Details" },
        ]}
      />
      <section className="uploadSection">
        <div className="UploadCard">
          <Row>
            <div style={{ textAlign: "center", fontSize: 20 }}>
              {orderDetails && (
                <div style={{ marginBottom: "2rem" }}>
                  <div className="success-checkmark">
                    <div className="check-icon">
                      <span className="icon-line line-tip"></span>
                      <span className="icon-line line-long"></span>
                      <div className="icon-circle"></div>
                      <div className="icon-fix"></div>
                    </div>
                  </div>

                  <span className="cake-card-content">
                    <div
                      className="cake-card-heading-text"
                      style={{ marginTop: "1rem" }}
                    >
                      Order Number :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {orderDetails.order_number}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Date :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {moment(orderDetails.created_at).format("DD/MM/YYYY")}{" "}
                        {orderDetails.created_at.split(" ")[1]}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Total :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        AED {orderDetails.total}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Vendor Name :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {orderDetails.vendor_name}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Payment Status :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {orderDetails.status == 0
                          ? "Pending"
                          : orderDetails.status == 1
                          ? orderDetails.processing_status
                            ? `${orderDetails.processing_status}`
                            : ""
                          : "Failed"}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Fullfillment Status :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {orderDetails.fullfillment_status.status_id == 0
                          ? "Pending"
                          : orderDetails.fullfillment_status.status_id == 1
                          ? "Success"
                          : "Failed"}
                      </span>
                    </div>
                    <div className="cake-card-heading-text">
                      Items :{" "}
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            border: "2px solid",
                            padding: "1rem",
                            width: "max-content",
                            textAlign: "left",
                          }}
                        >
                          {orderDetails.items.map((item, i) => (
                            <>
                              <div>
                                {" "}
                                <span style={{ fontWeight: "normal" }}>
                                  Item name :
                                </span>
                                {item.title}
                              </div>
                              <div>
                                <span style={{ fontWeight: "normal" }}>
                                  Item Quantity :
                                </span>{" "}
                                {item.qty}
                              </div>
                            </>
                          ))}
                        </div>
                      </span>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </Row>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderDetails));
