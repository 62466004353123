import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import $ from "jquery";
import { useParams } from "react-router-dom";
import ProductList from "../../components/productList";
import CategoryList from "../../components/CategoryList";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import videoLogo from "../../../src/assets/images/gshar-video.jpg";
import moment from "moment";
import "./style1.scss";
import category1 from "../../../src/assets/images/category1.png";
import category2 from "../../../src/assets/images/category2.png";
import category3 from "../../../src/assets/images/category3.png";
import category4 from "../../../src/assets/images/category4.png";
import Product1 from "../../assets/images/kimono-gb8735578a_1280.jpg";
import Product2 from "../../assets/images/ao-dai-gd837bc657_640.jpg";
import randomVideo from "../../assets/videos/Metal_Wind_Chimes_at_Sunset.mp4";
import Product3 from "../../assets/images/asian-g29979ad92_640.jpg";
import playImage from "../../assets/images/play-image.png";
import VideoThumbnail from "../../assets/images/Video-thumbnail.jpeg";

import correcttick from "../../assets/images/icons/correcttick.svg";

import rightchevron from "../../assets/images/icons/rightchevron.svg";
import leftchevron from "../../assets/images/icons/leftchevron.svg";

import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import { Helmet } from "react-helmet";
import { useRef } from "react";

const ProductStore = (props) => {
  const [products, setProducts] = useState([]);
  const [destination, setDestination] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [sliderImg, setSliderImg] = useState();
  const productCarousel = useRef(null);
  const categoriesCarousel = useRef(null);

  localStorage.setItem("storeName", "gsharshop");
  const params = useParams();
  const currentHostname = window.location.hostname;
  const isDiscoverShurooqDomain =
    currentHostname === "discovershurooq.ae" ||
    currentHostname === "www.discovershurooq.ae" ||
    currentHostname === "localhost";

  const options = {
    nav: false,
    loop: false,
    autoplay: false,
    navText: [``, ``],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      767: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1200: {
        items: 4,
      },
      // 1600: {
      //   items: 5,
      // },
    },
  };

  const options2 = {
    loop: false,
    autoWidth: false,
    autoplay: true,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      767: {
        items: 3,
      },
      // 991: {
      //   items: 3,
      // },
      // 1200: {
      //   items: 3,
      // },
      // 1600: {
      //   items: 5,
      // },
    },
  };
  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyFormData = new FormData();
    bodyFormData.append("action", "storeDesign");
    bodyFormData.append(
      "url_slug",
      isDiscoverShurooqDomain ? params.slug : "gsharshop"
    );
    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      console.log(res.data);
      setSliderData(JSON.parse(res.data.storeData.highlights));
      setSliderImg(res.data.storeData.slider_images);
    });
    const bodyForm = new FormData();
    bodyForm.append("action", "storeData");
    axios.post(process.env.REACT_APP_BASE_URL, bodyForm).then((res) => {
      console.log(res.data);
      const destination = res.data.data.destinations.find(
        (item) =>
          item.url_slug == (isDiscoverShurooqDomain ? params.slug : "gsharshop")
      );
      const products = res.data.data.products.filter(
        (product) => product.vendors === destination.id
      );
      var sortedProducts = products.sort((a, b) =>
        moment(b.created_at.split("")[0]).diff(
          moment(a.created_at.split("")[0])
        )
      );
      setProducts(sortedProducts.splice(0, 20));
      setDestination(destination);
    });
  }, []);
  // const sliderData = [
  //   {
  //     title: "Summer Hats",
  //     image: Product1,
  //   },
  //   {
  //     title: "Men Hats",
  //     image: Product2,
  //   },
  //   {
  //     title: "Floral Dresses",
  //     image: Product3,
  //   },
  // ];
  const Slide = styled.div`
    display: flex;
    height: 350px;
    align-items: center;
    padding: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    filter: brightness(70%);
    transition: all 0.2s ease-in-out;
    border-radius: 20px;
    // .title {
    //   font-size: 25px;
    //   font-weight: 500;
    //   color: black;
    //   margin-bottom: 10px;
    // }
    // .shopnow {
    //   font-size: 16px;
    //   font-weight: 400;
    //   color: black;
    //   margin: 0;
    //   display: flex;
    //   align-items: center;
    //   gap: 10px;
    //   i {
    //     font-size: 14px;
    //   }
    // }
    // &:hover {
    //   background-position-x: -10px;
    //   .shopnow {
    //     gap: 20px;
    //   }
    // }
  `;

  const toggleModal = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
  };

  if (typeof window !== "undefined") {
    window.onclick = function (event) {
      var modal = document.getElementById("myModal");
      if (event.target === modal) modal.style.display = "none";
    };
  }

  return (
    <div>
      <Helmet>
        <title>Gshar</title>
        <meta
          name="description"
          content="The heart of handmade items from around the region, find unique & artsy gifts here!"
        />
        {/* Add other meta tags as needed */}
      </Helmet>
      <StoreMenu />
      <RegisterBanner sliderImg={sliderImg} productStore={true} />
      {/* <div className="slider-data container-fluid py-3">
        <Row className="m-0">
          {sliderData.map(
            (data) =>
              data.img && (
                <Col lg={4} md={4} xl={3} className="mb-4 slider">
                  <>
                    <Link to={data.link}>
                      <h4>{data.title}</h4>
                      <Slide style={{ backgroundImage: `url(${data.img})` }} />
                    </Link>
                  </>
                </Col>
              )
          )}
        </Row>
      </div> */}

      <div className="container" style={{ marginTop: "55px" }}>
        {true > 0 ? (
          <div id="categories-slider" className="products">
            {/* <div
              className="d-flex justify-content-end"
              style={{ width: "100%", marginBottom: "35px" }}
            >
              <div
                style={{
                  padding: "9px 15px",
                  border: "2px solid #F89828",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  categoriesCarousel.current.prev();
                }}
              >
                <img src={leftchevron} alt="" />
              </div>
              <div
                style={{
                  padding: "9px 15px",
                  border: "2px solid #F89828",
                  background: "#F89828",
                  borderRadius: "8px",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  categoriesCarousel.current.next();
                }}
              >
                <img src={rightchevron} alt="" />
              </div>
            </div> */}
            {sliderData.length > 0 && (
              <OwlCarousel
                ref={categoriesCarousel}
                options={{ ...options, dots: false }}
              >
                {sliderData.map((product, index) =>
                  index <= 12 && product.img ? (
                    <div className={`product-item `}>
                      <CategoryList product={product} />
                    </div>
                  ) : (
                    ""
                  )
                )}
              </OwlCarousel>
            )}
          </div>
        ) : (
          <h5>No Products for this Destination</h5>
        )}
      </div>

      {/* <div className="store_look container" style={{ padding: "20px" }}>
        <div style={{ margin: "auto" }}>
          <h3 style={{ fontSize: "60px", color: "#F89828", margin: 0 }}>
            About the
          </h3>
          <h3 style={{ fontSize: "60px", color: "#202434", margin: 0 }}>
            artists
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "8%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                }}
              >
                What you can find at Gshar is uniquely handcrafted items by
                talented artists and designers that we have handpicked to
                showcase their work, helping you find special gifts and
                collectibles.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "4%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                }}
              >
                Gshar opened its doors in September 2018 and have proudly
                collaborated with over 17 creative designers since.
              </div>
            </div>
          </div>
        </div>
        <div className="videoplay_img" onClick={toggleModal}>
          <img id="selectimg" src={videoLogo} />
          <img className="play_image" src={playImage} />
        </div>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <iframe
              title="s"
              className="youtube-video"
              src="https://www.youtube.com/embed/9hcd0h_1MWU"
              // width="1000"
              // height="600"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div> */}

      {/* <div
        style={{
          background: "#E4EBF3",
          width: "100%",
          padding: "150px 0",
          marginTop: "120px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80%",
          }}
        >
          <div
            className="d-flex justify-content-between align-items-end"
            style={{ marginBottom: "30px" }}
          >
            <h2 className="product-sec-title">
              gshar <span style={{ color: "#F89828" }}>Shop</span>
            </h2> */}

      <div
        style={{
          background: "#E4EBF3",
          width: "100%",
          padding: "60px 0",
          marginTop: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container">
          <div
            className="d-flex justify-content-between align-items-end"
            style={{ marginBottom: "30px" }}
          >
            <h2 className="product-sec-title">
              Gshar <span style={{ color: "#F89828" }}>Shop</span>
            </h2>
            <h6 className="cursor-pointer">
              <a href={`/stores/gsharshop/products`} className="text-body">
                See All{" "}
                <i
                  className="fas fa-long-arrow-alt-right"
                  style={{ color: "#F89828", marginLeft: ".5rem" }}
                ></i>
              </a>
            </h6>
          </div>
          {products.length > 0 ? (
            <div id="product-slider" className="products">
              <OwlCarousel
                ref={productCarousel}
                options={{ ...options, dots: false }}
              >
                {products.map((product, index) => (
                  <div
                    key={product.product_id}
                    className={`product-item ${
                      index == 0 ? "product-item-first" : ""
                    }`}
                  >
                    <ProductList product={product}></ProductList>
                  </div>
                ))}
              </OwlCarousel>
              <div
                className="d-flex justify-content-end"
                style={{ width: "100%", marginTop: "35px" }}
              >
                <div
                  style={{
                    padding: "9px 15px",
                    cursor: "pointer",
                    border: "2px solid #F89828",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    productCarousel.current.prev();
                  }}
                >
                  <img src={leftchevron} alt="" />
                </div>
                <div
                  style={{
                    padding: "9px 15px",
                    cursor: "pointer",
                    border: "2px solid #F89828",
                    background: "#F89828",
                    borderRadius: "8px",
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    productCarousel.current.next();
                  }}
                >
                  <img src={rightchevron} alt="" />
                </div>
              </div>
            </div>
          ) : (
            <h5>No Products for this Destination</h5>
          )}
        </div>
      </div>

      <div data-mc-src="05da698b-8575-49f5-ad1b-05d796687a76#instagram"></div>
      <Helmet>
        <script
          src="https://cdn2.woxo.tech/a.js#643e3e7d5a4f1d5697c7fabd"
          async
          data-usrc
        ></script>
      </Helmet>
      <div style={{ background: "#E4EBF3", padding: "100px 0" }}>
        <div
          className="container"
          style={{
            background: "#E7881A",
            borderRadius: "15px",
          }}
        >
          <div
            className="row"
            style={{
              padding: "50px 75px",
            }}
          >
            <EcommerceGuarantees />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(withRouter(ProductStore));
