import React, { useEffect, useState } from "react";
import UploadBanner from "../../components/banners/UploadBanner";
import { Row, Col, Input, Button } from "reactstrap";
import cx from "classnames";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as userAction from "../../actions/user-action-type";
import { withRouter } from "react-router-dom";
import { getLanguage, setLanguage, useTranslation } from "react-multi-lang";
import ArrowIcon from "../../assets/images/icons/left-arrow.svg";
import moment from "moment";

const HosCardsOrderHistory = (props) => {
  const t = useTranslation();
  const isEnglish = localStorage.getItem("user_language");
  const { hosCardsOrderHistoryData, getHosCardsOrderHistory } = props;
  const [hosCardsHistory, setHosCardsHistory] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
    const data = new FormData();
    data.append("action", "listPostCardOrders");
    getHosCardsOrderHistory(data);
  }, []);

  useEffect(() => {
    console.log(hosCardsOrderHistoryData);
    const recentHistory = hosCardsOrderHistoryData.sort(
      (a, b) => moment(b.created_at) - moment(a.created_at)
    );
    setHosCardsHistory(recentHistory);
  }, [hosCardsOrderHistoryData]);

  return (
    <div>
      <UploadBanner
        linkPage={[
          { type: "link", name: "Home", link: "/" },
          { name: "Post Card Orders" },
        ]}
      />
      <section className="uploadSection">
        <div className="UploadCard">
          <Row>
            <Col md={3}>{/* <UploadContent /> */}</Col>
            <Col md={6}>
              <div className="destinations-package">
                <div className="">
                  <div className="backContainer d-flex">
                    <p className="menu-text">
                      <i
                        className="fas fa-arrow-left arrow"
                        onClick={() => history.push("/settings")}
                      ></i>
                      Post-Card Order History
                    </p>

                    {/* <img src={ArrowIcon} height="20px" alt="Back icon" onClick={() => history.push({ pathname: "/settings" })} />
                    <div className="password_text">{t('settings.order_history')}</div> */}
                  </div>
                  {hosCardsHistory.length == 0 ? (
                    <Col
                      className="mb-3"
                      style={{
                        textAlign: "center",
                        marginTop: "200px",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center" }}>
                        {t("order_history.empty_message")}
                      </p>
                    </Col>
                  ) : (
                    hosCardsHistory.map((item, index) => (
                      <div key={index}>
                        <div className="cake-card-wrapper">
                          <div
                            className="cake-card-body ticket"
                            onClick={() =>
                              item.status == 1 &&
                              history.push(
                                `/hos-postcards/order-details/${item.order_number}`
                              )
                            }
                          >
                            {/* <span className="cake-card-left-img">
                              <img
                                src={
                                  "https://api.discovershurooq.ae/files/" +
                                  item.package_img
                                }
                                alt=""
                              />
                            </span> */}
                            <span className="cake-card-content">
                              <div
                                className="cake-card-heading-text"
                                style={{ marginTop: "1rem" }}
                              >
                                {item.order_number}
                              </div>
                              <div
                                className="cake-card-heading-text"
                                style={{ marginTop: "1rem" }}
                              >
                                {moment(item.created_at).format("DD/MM/YYYY")}{" "}
                                {item.created_at.split(" ")[1]}
                              </div>
                              <span className="cake-card-heading-text">
                                {isEnglish == "en" ? item.name : item.name}
                              </span>
                              <div
                                className="cake-card-heading-text"
                                style={{ marginTop: "1rem" }}
                              >
                                {item.amount}
                              </div>
                            </span>

                            <span
                              className="cake-card-right"
                              style={{
                                backgroundColor:
                                  item.status == 0
                                    ? "gray"
                                    : item.status == 1
                                    ? "green"
                                    : "red",
                                borderRadius: "0px 12px 12px 0px",
                              }}
                            >
                              {item.status == 0
                                ? "Pending"
                                : item.status == 1
                                ? item.processing_status
                                  ? `${item.processing_status}`
                                  : ""
                                : "Failed"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = ({ user: { hosCardsOrderHistoryData } }) => ({
  hosCardsOrderHistoryData,
});

const mapDispatchToProps = {
  getHosCardsOrderHistory: userAction.getHosCardsOrderHistory,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HosCardsOrderHistory));
