import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import Parser from "html-react-parser";
import { useParams } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { setCartData } from "../../actions/cart-action-type";
import CollectionImage from "../../assets/images/Collection-image.jpeg";
import DesignersBanner from "../../assets/images/designersbanner.png";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import styled from "styled-components";
import ProductList from "../../components/productList";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import ContentLoader from "react-content-loader";
import CollectionBanner from "../../components/banners/CollectionBanner";

const Designers = () => {
  const [collectionsProducts, setCollectionsProducts] = useState([]);
  const [tempCollectionsProducts, setTempCollectionsProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyFormData = new FormData();
    bodyFormData.append("action", "listCollections");
    bodyFormData.append("destination_slug", params.slug);
    window.scrollTo({ behavior: "smooth", top: 0 });

    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      console.log(res.data);
      setCollectionsProducts(res.data.data);
      setTempCollectionsProducts(res.data.data);
      setLoading(false);
    });
  }, []);

  const handleFilterCollections = (value) => {
    const filteredCollections = tempCollectionsProducts.filter(
      (tempProduct) =>
        tempProduct.collection_name
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
    setCollectionsProducts(filteredCollections);
  };

  const renderLoader = () => (
    <Row className="m-0">
      {["", "", "", "", "", "", "", ""].map((data, index) => (
        <Col className="mb-4" lg={3} md={6}>
          <ContentLoader
            speed={2}
            viewBox="0 0 638 520"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="1" y="0" rx="3" ry="3" width="600" height="800" />
          </ContentLoader>
        </Col>
      ))}
      ;
    </Row>
  );

  const Slide = styled.div`
    display: flex;
    height: 400px;
    align-items: center;
    justify-content: center;
    // padding: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    transition: all 0.2s ease-in-out;
    position: relative;
    h4 {
      position: absolute;
      bottom: 20px;
      margin: 0 30px;
      color: #fff;
    }
  `;

  return (
    <div className="productdetail">
      <StoreMenu />
      <CollectionBanner
        backgroundImage={DesignersBanner}
        titleArr={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "Designers",
            link: `/stores/gsharshop/collections`,
          },
        ]}
        title="Designers"
      />
      <div
        className="container"
        style={{ marginTop: "100px", marginBottom: "50px" }}
      >
        <Row className="mt-2">
          {!loading ? (
            <>
              {collectionsProducts.map(
                (collectionsProduct) =>
                  Number(collectionsProduct.qty) > 0 &&
                  collectionsProduct.is_profile === "1" && (
                    <Col
                      className="mb-4"
                      key={collectionsProduct.id}
                      lg={3}
                      md={6}
                      sm={8}
                      xs={12}
                    >
                      <Slide>
                        <a
                          href={`/stores/${params.slug}/designers/${collectionsProduct.slug}`}
                        >
                          {/* <img
                    alt={collectionsProduct.slug}
                    src={
                      collectionsProduct.logo
                        ? `https://bo.discovershurooq.ae/cdn-cgi/image/width=260,format=auto/files/${collectionsProduct.logo}`
                        : CollectionImage
                    }
                    width="100%"
                    height="400"
                    style={{ filter: "brightness(70%)", borderRadius: "15px" }}
                  />
                  <h4>{collectionsProduct.collection_name}</h4> */}
                          <div
                            className="category-grid"
                            style={{
                              background: collectionsProduct.logo
                                ? `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${collectionsProduct.logo})`
                                : CollectionImage,
                              minWidth: "275px",
                            }}
                          >
                            <h6
                              style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                color: "#fff",
                              }}
                            >
                              {collectionsProduct.collection_name}
                            </h6>
                          </div>
                        </a>
                      </Slide>
                    </Col>
                  )
              )}
            </>
          ) : (
            renderLoader()
          )}
        </Row>
      </div>
      {!collectionsProducts.filter((collec) => collec.is_profile === "1")
        .length &&
        !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "4rem",
              fontSize: 30,
              fontWeight: 600,
            }}
          >
            No products Found
          </div>
        )}
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "100px",
        }}
      >
        <button
          style={{
            padding: "12px 50px",
            backgroundColor: "#F89828",
            borderRadius: "12px",
            boxShadow: "0px 7px 10px rgba(0, 0, 0, 0.05)",
            border: "none",
            fontSize: "16px",
            fontWeight: 700,
            color: "#fff",
          }}
        >
          More
        </button>
      </div> */}
    </div>
  );
};

export default connect()(withRouter(Designers));
