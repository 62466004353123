import { useEffect } from "react";
import Layout from "../components/layout";
import {
  Home,
  BookYourExperience,
  Package,
  PackageDetail,
  Register,
  Login,
  VoucherDetails,
  Upload,
  Settings,
  Editprofile,
  OrderHistory,
  Vouchers,
  NearbyVouchers,
  SearchDetail,
  AdventurousDetails,
  Invoics,
  AllRaffles,
  BookingDetails,
  AboutUs,
  ContactUs,
  ViewAll,
  OfferDestinations,
  ScratchAndWin,
  Terms,
  Privacy,
  Gallery,
  Cart,
  Store,
  ProductStore,
  ProductDetail,
  CheckOut,
  OrderSucess,
  Purchasehistory,
  Collection,
  Collections,
  Tags,
  About,
  Products,
  HosCards,
  MessageHosCards,
  Designer,
  Designers,
  Orders,
  EcomOrderDetails,
  OrderDetails,
  OrdersSettings,
} from "../pages";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import qs from "qs";
import { useCookies } from "react-cookie";
import EditHosCards from "../pages/Edit-Hos-Cards";
import HosCardsOrderHistory from "../pages/HosCardsOrderHistory";
import AboutHosCards from "../pages/About-Hos-Cards";
import ContactHosCards from "../pages/Contact-Hos-Cards";
import HosCardsOrderDetails from "../pages/HosCardsOrderDetails";
// import LayoutWithoutHF from "../components/layout/LayoutWithoutHF";

function Routes() {
  const location = useLocation();
  const [cookies, setRefCookie, removeCookie] = useCookies();

  useEffect(() => {
    const { ref = "" } = qs.parse(location.search.slice(1));
    if (ref.length) setRefCookie("ref", ref);
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 4);
    console.log(document.referrer);
    if (document.referrer.length) {
      removeCookie("traffic_referral");
      setRefCookie("traffic_referral", document.referrer, {
        expires: expiryDate,
      });
    }
  }, [location.search]);

  const currentHostname = window.location.hostname;
  console.log(currentHostname);

  return (
    <Switch>
      <Redirect exact from="/stores/" to="/stores/gsharshop" />
      <Layout>
        {currentHostname === "discovershurooq.ae" ||
        currentHostname === "www.discovershurooq.ae" ||
        currentHostname === "localhost" ? (
          <Route exact path="/" component={Home} />
        ) : (
          <Route exact path="/" component={ProductStore} />
        )}
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/d/:url_slug" component={BookYourExperience} />
        <Route
          exact
          path="/book-your-experience"
          component={BookYourExperience}
        />
        <Route exact path="/package" component={Package} />
        <Route exact path="/p/:package_slug" component={PackageDetail} />
        <Route exact path="/v/:voucher_slug" component={VoucherDetails} />
        <Route
          exact
          path="/:settingsType(settings|orders-settings)"
          component={Settings}
        />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/voucher-details" component={VoucherDetails} />
        <Route
          exact
          path="/stores/:slug/checkout-address"
          component={CheckOut}
        />
        <Route exact path="/upload" component={Upload} />
        <Route exact path="/edit-profile" component={Editprofile} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/order-payment-complete" component={OrderSucess} />
        <Route exact path="/payment-history" component={Purchasehistory} />
        <Route
          exact
          path="/booking-details/:order_number"
          component={BookingDetails}
        />
        <Route exact path="/vouchers" component={Vouchers} />
        <Route exact path="/vouchers/:url_slug" component={NearbyVouchers} />
        <Route exact path="/s/:t/:d" component={SearchDetail} />
        <Route exact path="/t/:slug" component={AdventurousDetails} />
        <Route exact path="/invoics" component={Invoics} />
        <Route exact path="/all-raffles" component={AllRaffles} />
        <Route exact path="/view-all-destination" component={ViewAll} />
        <Route exact path="/seesharjah" component={OfferDestinations} />
        <Route exact path="/scratch" component={ScratchAndWin} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/stores/:slug/cart" component={Cart} />
        <Route exact path="/stores" component={Store} />
        <Route exact path="/stores/:slug" component={ProductStore} />
        <Route exact path="/stores/:slug/products" component={Products} />
        <Route exact path="/stores/:slug/pages/:page_slug" component={About} />
        <Route exact path="/stores/:slug/tag/:tag_slug" component={Tags} />
        <Route
          exact
          path="/stores/:slug/product_detail/:product_slug"
          component={ProductDetail}
        />
        <Route exact path="/stores/:slug/collections" component={Collections} />
        <Route
          exact
          path="/stores/:slug/collections/:collection_slug"
          component={Collection}
        />
        <Route exact path="/stores/:slug/designers" component={Designers} />
        <Route
          exact
          path="/stores/:slug/designers/:designer_slug"
          component={Designer}
        />
        <Route
          exact
          path="/stores/ecom-order-details/:order_number"
          component={EcomOrderDetails}
        />
        <Route
          exact
          path="/stores/orders/:order_number"
          component={EcomOrderDetails}
        />
        <Route exact path="/stores/:slug/orders" component={Orders} />
        <Route
          exact
          path="/stores/:slug/orders/:order_number"
          component={OrderDetails}
        />
        <Route exact path="/hos-postcards" component={HosCards} />
        <Route exact path="/hos-postcards-about" component={AboutHosCards} />
        <Route
          exact
          path="/hos-postcards-contact"
          component={ContactHosCards}
        />
        <Route
          exact
          path="/hos-postcards/edit-my-card/:card_slug"
          component={EditHosCards}
        />
        <Route
          exact
          path="/hos-postcards/message"
          component={MessageHosCards}
        />
        <Route
          exact
          path="/hos-postcards/order-history"
          component={HosCardsOrderHistory}
        />
        <Route
          exact
          path="/hos-postcards/order-details/:order_number"
          component={HosCardsOrderDetails}
        />
      </Layout>
    </Switch>
  );
}

export default Routes;
