import React, { useEffect, useState, useMemo, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import RegisterBanner from "../../components/banners/RegisterBanner";
import StoreMenu from "../../components/layout/header/store-menu";
import axios from "axios";
import Parser from "html-react-parser";
import { useParams } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import OwlCarousel from "react-owl-carousel2";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import "./style.scss";
import { setCartData } from "../../actions/cart-action-type";
import TrustedLogo from "../../../src/assets/images/logos/trusted.svg";
import ContentLoader from "react-content-loader";
import filter from "../../../src/assets/images/icons/filter.svg";
import innerdesignersbanner from "../../../src/assets/images/gshar-video.jpg";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import ProductList from "../../components/productList";
import EcommerceGuarantees from "../../components/ecommerce-guarantees";
import globalConfig from "../../globalConfig";
import Filters from "../../components/filters";
import CollectionBanner from "../../components/banners/CollectionBanner";
import ProductPagination from "../../components/product-pagination";
import styled from "styled-components";
import TagsFilter from "../../components/TagsFilter";

const Collections = (props) => {
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [tempCollectionProducts, setTempCollectionProducts] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryData, setCategoryData] = useState();
  const params = useParams();

  useEffect(() => {
    globalConfig.isStorePage = true;
    const bodyFormData = new FormData();
    bodyFormData.append("action", "listProductsBySlug");
    bodyFormData.append("destination_slug", params.slug);
    bodyFormData.append("category_slug", params.collection_slug);

    window.scrollTo({ behavior: "smooth", top: 0 });

    axios.post(process.env.REACT_APP_BASE_URL, bodyFormData).then((res) => {
      console.log(res.data);
      setCollectionProducts(
        Object.keys(res.data.data).map((k) => res.data.data[k])
      );
      setTempCollectionProducts(
        Object.keys(res.data.data).map((k) => res.data.data[k])
      );
      setCategoryData(res.data.catgData);
      setLoading(false);
    });
  }, []);

  const handleFilterTags = (selectedTag) => {
    if (selectedTag === "All") {
      setTagsList((prev) => {
        prev.map((list) => {
          list.active = false;
          return list;
        });
        return prev;
      });
      setCollectionProducts(tempCollectionProducts);
    } else {
      setTagsList((prev) => {
        prev.map((list) => {
          if (list.value === selectedTag) {
            list.active = true;
          } else {
            list.active = false;
          }
          return list;
        });
        return prev;
      });
      const filteredCollections = tempCollectionProducts.filter(
        (temp) =>
          temp.tags && temp.tags.find((tag) => tag.value === selectedTag)
      );
      setCollectionProducts(filteredCollections);
    }
  };

  const options1 = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  const renderLoader = () => (
    <Row className="m-0">
      {["", "", "", "", "", "", "", ""].map((data, index) => (
        <Col className="mb-4" lg={3} md={6}>
          <ContentLoader
            speed={2}
            viewBox="0 0 520 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="1" y="0" rx="3" ry="3" width="700" height="1000" />
          </ContentLoader>
        </Col>
      ))}
      ;
    </Row>
  );

  const filteredData = useMemo(() => {
    document
      .getElementById("collection-container")
      ?.scrollIntoView({ behavior: "smooth" });
    let tempTableData = collectionProducts.filter((prod) => prod.hide === "0");

    setTotalData(tempTableData.length);
    return tempTableData.slice(
      (currentPage - 1) * 20,
      (currentPage - 1) * 20 + 20
    );
  }, [collectionProducts, currentPage]);

  const Slide = styled.div`
    display: flex;
    height: 400px;
    align-items: center;
    justify-content: center;
    // padding: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    transition: all 0.2s ease-in-out;
    position: relative;
    h4 {
      position: absolute;
      bottom: 20px;
      margin: 0 30px;
      color: #fff;
    }
  `;

  return (
    <div className="productdetail">
      <StoreMenu />
      {categoryData && (
        <CollectionBanner
          backgroundImage={categoryData.featured_image}
          titleArr={[
            {
              title: "Home",
              link: "/",
            },
            {
              title: categoryData?.collection_name,
              link: `/stores/gsharshop/collections/${params.collection_slug}`,
            },
          ]}
          title={categoryData?.collection_name}
        />
      )}
      {/* <Filters
          products={collectionProducts}
          setProducts={setCollectionProducts}
          tempProducts={tempCollectionProducts}
        /> */}

      {/* <div className="container" >
        <Row className="mt-2">
        {[
    {
        "product_id": "23",
        "product_title": "FMM Pins",
        "product_description": "The ultimate FMM funky cultural pins, can be pinned on such things as clothing and bags<br>",
        "collections": "[\"13\"]",
        "vendors": "0",
        "images": "[\"FMM01_pin 16257c00a21e3d.png\",\"FMM01_pin 26257c00a220d7.png\",\"FMM01_pin 36257c00a22236.png\",\"FMM01_pin 46257c00a22258.png\",\"FMM01_pin 56257c00a223b5.png\",\"FMM01_pin 66257c00a22501.png\",\"FMM01_pin 76257c00a22520.png\",\"FMM01_pin 86257c00a22540.png\"]",
        "tags": "[{\"value\":\"Fmm pins\"},{\"value\":\"pins\"}]",
        "sku": "FMM01",
        "price": "0",
        "qty": null,
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "FMM-Pins-4059",
        "created_at": "2022-04-13 08:39:14",
        "updated_at": "2022-05-31 06:32:59"
    },
    {
        "product_id": "24",
        "product_title": "FMM Notebooks",
        "product_description": "<br>",
        "collections": "[\"13\"]",
        "vendors": "132",
        "images": "[\"FMM02_Notebook6257c06848ec6.png\"]",
        "tags": "",
        "sku": "FMM02",
        "price": "79",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "FMM-Notebooks-3165",
        "created_at": "2022-04-13 08:40:20",
        "updated_at": "2022-07-24 06:23:58"
    },
    {
        "product_id": "25",
        "product_title": "FMM Stickers",
        "product_description": "<div>Plush stickers, made specially to celebrate things that are vintage<br></div>",
        "collections": "[\"13\"]",
        "vendors": "0",
        "images": "[\"FMM11_sticker 16257c09e5b23a.png\",\"FMM11_sticker 26257c09e5b3dd.png\",\"FMM11_sticker 36257c09e5b52d.png\"]",
        "tags": "",
        "sku": "FMM11",
        "price": "16",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "FMM-Stickers-6884",
        "created_at": "2022-04-13 08:41:05",
        "updated_at": "2022-05-31 06:32:24"
    },
    {
        "product_id": "26",
        "product_title": "Sticker Pack",
        "product_description": "<div>Plush stickers, made specially to celebrate things that are vintage (pack of 4)<br></div>",
        "collections": "[\"13\"]",
        "vendors": "132",
        "images": "[\"FMM12_Sticker pack6257c0c2e3c24.png\"]",
        "tags": "",
        "sku": "FMM12",
        "price": "58",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Sticker-Pack-9535",
        "created_at": "2022-04-13 08:41:36",
        "updated_at": "2022-04-14 06:35:46"
    },
    {
        "product_id": "27",
        "product_title": "Classic Collection Dress",
        "product_description": "<div>Classic special edition dresses, each design is colourful with a touch of tradtional designs&nbsp;<br></div>",
        "collections": "[\"13\"]",
        "vendors": "132",
        "images": "[\"FMM13_Classic collection dresses 16257c155ef947.png\",\"FMM13_Classic collection dresses 26257c155ef9b0.png\",\"FMM13_Classic collection dresses 36257c155ef9d3.png\",\"FMM13_Classic collection dresses 46257c155ef9f2.png\",\"FMM13_Classic collection dresses 56257c155efa10.png\",\"FMM13_Classic collection dresses 66257c155efa2f.png\",\"FMM13_Classic collection dresses 76257c155efa4e.png\",\"FMM13_Classic collection dresses 86257c155efa6c.png\",\"FMM13_Classic collection dresses 96257c155efa89.png\"]",
        "tags": "",
        "sku": "FMM13",
        "price": "494",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Classic-Collection-Dress-5305",
        "created_at": "2022-04-13 08:42:08",
        "updated_at": "2022-04-14 06:38:13"
    },
    {
        "product_id": "28",
        "product_title": "Sticker Sheet - Squid Game",
        "product_description": "<div>Squid Game? Yes the extremely popular series, Fmm has crafted out a little sticker sheet for any fans out there.<br></div>",
        "collections": "[\"13\"]",
        "vendors": "132",
        "images": "[\"FMM14_Squid Game stickers6257c22ac062f.png\"]",
        "tags": "[{\"value\":\"ok\"}]",
        "sku": "FMM14",
        "price": "27",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Sticker-Sheet---Squid-Game-3783",
        "created_at": "2022-04-13 08:42:45",
        "updated_at": "2022-04-25 17:39:01"
    },
    {
        "product_id": "29",
        "product_title": "MNAWRAH Postcards",
        "product_description": "<div>MNWARAH'S post cards are dedicated to celebrate UAE culture, each postcard has a different design of a local identity<br></div>",
        "collections": "[\"14\"]",
        "vendors": "132",
        "images": "[\"MNAW01_Post card 16257c31aa2d92.png\",\"MNAW01_post card 26257c31aa2df6.png\",\"MNAW01_post card 36257c31aa2e18.png\",\"MNAW01_post card 46257c31aa2fa2.png\",\"MNAW01_post card 56257c31aa2fc5.png\",\"MNAW01_post card 66257c31aa2fe2.png\",\"MNAW01_post card 76257c31aa3000.png\",\"MNAW01_post card 86257c31aa318b.png\",\"MNAW01_post card 96257c31aa330d.png\",\"MNAW01_post card 106257c31aa3332.png\",\"MNAW01_post card 116257c31aa3350.png\",\"MNAW01_post card 126257c31aa336f.png\"]",
        "tags": "",
        "sku": "MNAW01",
        "price": "18",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "MNAWRAH-Postcards-3031",
        "created_at": "2022-04-13 08:43:33",
        "updated_at": "2022-04-14 06:45:46"
    },
    {
        "product_id": "30",
        "product_title": "Emarati Family Stickers",
        "product_description": "<div>MNWARAH'S stickers are dedicated to celebrate UAE culture, each sticker has a different design of a local identity, they can be placed on notebooks, laptops and phone covers<br></div>",
        "collections": "[\"14\"]",
        "vendors": "132",
        "images": "[\"MNAW02_Emarati  Famil stickers 26257c48c9e8e6.png\",\"MNAW02_Emarati family stickers 36257c48c9eb07.png\",\"MNAW02_Emarati Family Stickers6257c48c9ec86.png\"]",
        "tags": "",
        "sku": "MNAW02",
        "price": "29",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Emarati-Family-Stickers-3478",
        "created_at": "2022-04-13 08:44:00",
        "updated_at": "2022-04-14 06:51:56"
    },
    {
        "product_id": "31",
        "product_title": "Holograph Stickers",
        "product_description": "<div>MNWARAH'S stickers are dedicated to celebrate UAE culture, each sticker has a different design of a local identity, they can be placed on notebooks, laptops and phone covers<br></div>",
        "collections": "[\"14\"]",
        "vendors": "132",
        "images": "[\"MNAW03_Holograph Stickers 16257c5c0c27f1.png\",\"MNAW03_Holograph Stickers 26257c5c0c2850.png\",\"MNAW03_Holograph stickers 36257c5c0c2873.png\"]",
        "tags": "",
        "sku": "MNAW03",
        "price": "16",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Holograph-Stickers-3985",
        "created_at": "2022-04-13 08:44:30",
        "updated_at": "2022-04-14 06:57:04"
    },
    {
        "product_id": "32",
        "product_title": "Youth Stickers",
        "product_description": "<div>MNWARAH'S stickers are dedicated to celebrate UAE culture, each sticker has a different design of a local identity, they can be placed on notebooks, laptops and phone covers<br></div>",
        "collections": "[\"14\"]",
        "vendors": "132",
        "images": "[\"MNAW04_Youth stickers 16257c5f4b7eb2.png\"]",
        "tags": "",
        "sku": "MNAW04",
        "price": "18",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Youth-Stickers-7588",
        "created_at": "2022-04-13 08:44:59",
        "updated_at": "2022-04-14 06:57:56"
    },
    {
        "product_id": "33",
        "product_title": "Confessions Working Lady Zine Note",
        "product_description": "<div><br></div>",
        "collections": "[\"14\"]",
        "vendors": "132",
        "images": "[]",
        "tags": "",
        "sku": "MNAW05",
        "price": "29",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Confessions-Working-Lady-Zine-Note-8533",
        "created_at": "2022-04-13 08:45:22",
        "updated_at": "2022-04-14 05:35:27"
    },
    {
        "product_id": "34",
        "product_title": "Doodle Tote Bag",
        "product_description": "<div>One of MNWARAH'S favorite pieces, doodling her life, printed on a tote bag<br></div>",
        "collections": "[\"14\"]",
        "vendors": "132",
        "images": "[\"MNAW06_Doodle tote bag6257c6526bf94.png\"]",
        "tags": "",
        "sku": "MNAW06",
        "price": "53",
        "qty": "999",
        "out_of_stock_msg": "",
        "variation_group": null,
        "variation_attributes": "[]",
        "variants": "null",
        "start_date": "1970-01-01",
        "end_date": "1970-01-01",
        "enable": "1",
        "slug": "Doodle-Tote-Bag-8092",
        "created_at": "2022-04-13 08:45:53",
        "updated_at": "2022-04-14 06:59:30"
    },
    
].map(product=>( 
         
            <Col
              className="mb-4"              
              lg={3}
              md={6}
              sm={6}
              xs={12}
            >
            <Slide>
              
               <ProductList product={product}></ProductList>
                
              </Slide>
            </Col>
          
        )
          
          )}
          </Row>
      </div> */}

      <div className="container py-5">
        {/* <Row className="m-3">
          <TagsFilter
            tempProducts={tempCollectionProducts}
            setProducts={setCollectionProducts}
          />
        </Row> */}
        {/* {categoryData && (
          <div className="collection-container">
            <div className="collection-div">
              {categoryData?.logo && categoryData?.logo[0] && (
                <img
                  src={`https://bo.discovershurooq.ae/cdn-cgi/image/width=350,height=400,format=auto/files/${categoryData.logo[0]}`}
                  className="collection-author-img"
                />
              )}
              <div style={{ padding: "10px" }}>
                <div className="collection-name">
                  {categoryData?.collection_name}
                </div>
                <div className="collection-desc">
                  {categoryData?.description &&
                    Parser(categoryData.description, options1)}
                </div>
              </div>
            </div>
          </div>
        )} */}

        {!loading ? (
          <Row className="m-0" id="collection-container">
            {filteredData.map(
              (collectionProduct) =>
                Number(collectionProduct.qty) > 0 &&
                collectionProduct.hide === "0" && (
                  <Col
                    className="mb-4"
                    key={collectionProduct.product_id}
                    lg={3}
                    md={6}
                  >
                    <ProductList product={collectionProduct}></ProductList>
                  </Col>
                )
            )}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <ProductPagination
                total={totalData}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </span>
          </Row>
        ) : (
          renderLoader()
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "100px",
        }}
      >
        {!filteredData.length && !loading && (
          <p style={{ fontWeight: "600", fontSize: 24 }}>No products found</p>
        )}
      </div>
    </div>
  );
};

export default connect()(withRouter(Collections));
