import React from "react";
import styled from "styled-components";
import truck from "../../assets/images/icons/truck.svg";
import love from "../../assets/images/icons/love.svg";
import security from "../../assets/images/icons/security.svg";
import styles from "../../assets/images/icons/styles.svg";
import returns from "../../assets/images/icons/returns.svg";

function EcommerceGuarantees() {
  const Wrapper = styled.div`
    > * {
      flex: 1 1 33.33%;
      @media screen and (max-width: 767px) {
        flex: 1 1 100%;
      }
    }
    i {
      color: #fff;
      font-size: 14px;
    }
    .title {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 5px;
    }
    .dec {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7) !important;
      margin: 0;
    }
  `;
  return (
    <Wrapper className="d-flex gap-5 gap-md-3 flex-column flex-md-row">
     
     
    
    
      <div className="d-flex flex-column justify-content-between gap-3 align-items-start">
        <img
          src={security}
          alt=""
          style={{ marginBottom: "15px", width: "64px", height: "64px" }}
        />
        <div className="content">
          <p className="title">Secure Shopping</p>
          <p className="dec">You're in safe hands</p>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between gap-3 align-items-start">
        <img
          src={styles}
          alt=""
          style={{ marginBottom: "15px", width: "64px", height: "64px" }}
        />
        <div className="content">
          <p className="title">Local designers</p>
          <p className="dec">Over 10 local and regional designers</p>
        </div>
      </div>

         
    
      <div className="d-flex flex-column justify-content-between gap-3 align-items-start">
        <img
          src={love}
          alt=""
          style={{ marginBottom: "15px", width: "64px", height: "64px" }}
        />
        <div className="content">
          <p className="title">Handmade with love</p>
          <p className="dec">Crafted with care</p>
        </div>
      </div>


    </Wrapper>
  );
}

export default EcommerceGuarantees;
